import { Culture } from "andculturecode-javascript-core";
import { CultureCodes } from "constants/localization/culture-codes";
import { cultureFactory } from "utilities/localization/culture-factory";
import { CultureResources } from "utilities/interfaces/culture-resources";
import { UnicodeCharacterConstants } from "constants/unicode-character-constants";

// -------------------------------------------------------------------------------------------------
// #region Translations
// -------------------------------------------------------------------------------------------------

const internalEnglishTranslations = {
    cancelled: "Cancelled",
    both: "Both",
    remotely: "Remotely",
    aenDirectoryAvailabilityBoth:
        "Both. I will be available to all providers in the AEN instructor directory AND an instructor for a specific organization.",
    aenDirectoryAvailabilityNo:
        "No, I will be available to all providers in the AEN instructor directory.",
    aenDirectoryAvailabilityYes: "Yes, I will only be an instructor for a specific organization.",
    doYouWantToBeAnApprovedInstructorForASpecificAENProvider:
        "Do you want to be an approved instructor for a specific AEN Provider?",
    doYouPlanToOfferTraining: "Do you plan to offer training:",
    catalogPath: "AENbooks",
    ToMakeTheChange: " to make the change.",
    contactTheAENAdministrator: "contact the AEN administrator",
    yourExclusiveDiscountCodeIsTiedToTheAdminEmailEnteredUponRegistrationForTheNFPAAuthorizedEducationNetworkIfYouWouldLikeToUseADifferentEmailPlease:
        "*Your exclusive discount code is tied to the admin email entered upon registration for the NFPA Authorized Education Network. If you would like to use a different e-mail, please ",
    ifYouWantToProvidePrintedNFPACodesStandardsToYourStudentsYouCanPurchaseThemAtADiscountedPriceFromNFPAPleaseNoteYouAreStillResponsibleForShippinghandlingAndApplicableTaxesOnOrdersPlacedFromNFPAAndYourProviderDiscountIsOnlyForPrintPublicationsUsedForTrainingClassesBooksAreNotForResaleForOrdersPlacedOutsideTheUSPleasePlanForAnAdditionalWeeksForDeliveryAndAnyAdditionalLocalAndImportTaxes:
        "If you want to provide printed NFPA codes & standards to your students, you can purchase them at a discounted price from NFPA*. Please note, you are still responsible for shipping/handling and applicable taxes on orders placed from NFPA, and your provider discount is only for print publications used for training classes. Books are not for resale. For orders placed outside the U.S., please plan for an additional 3 weeks for delivery and any additional local and import taxes.",
    toRunThisReportYouMustSelectEitherAnEventsOrADateRangeYouMayAlsoSelectBoth:
        "To run this report, you must select at least one event and/or a date range below.",
    notApplicable: "Not Applicable",
    typeToSearchByProviderIdOrName: "Type to search by provider id or name",
    pleaseConfirmThatAllLearnersAreMarkedAsAttendedOrNoShow:
        "Please confirm that all Learners are marked as Attended or No Show.",
    noShow: "No Show",
    notAttended: "Not Attended",
    partiallyAttended: "Partially Attended",
    youCanNotEditContentFromATrainTheTrainerProductInOrderToEditContentYouMustEditTheAssociatedProduct:
        "You can not edit content from a Train the Trainer product. In order to edit content you must edit the associated product.",
    youAreViewingContentFromTheAssociatedTrainingProduct:
        "You are viewing content from the associated Training product.",
    aenInstructorInformationPage: "AEN Instructor Information Page",
    forMoreInformationOnBecomingAnNFPAInstructorPleaseVisit:
        "For more information on becoming an NFPA Instructor, please visit ",
    forSpecificFeedbackPleaseReferToTheEmailThatWasSentToYou:
        "For specific feedback, please refer to the email that was sent to you.",
    thankYouForTakingTheTimeToApplyToBeAnApprovedNFPAInstructorWeAppreciateYourInterestAndTheEffortYouPutIntoYourApplicationUnfortunatelyWeHaveFoundThatYourQualificationsDoNotMeetTheRequirementsAtThisTime:
        "Thank you for taking the time to apply to be an Approved NFPA Instructor. We appreciate your interest and the effort you put into your application. Unfortunately, we have found that your qualifications do not meet the requirements at this time.",
    approvedToTeachProduct: "Approved To Teach Product",
    instructorDirectory: "Instructor Directory",
    youAreNowEditingACompleteEvent: "You are now editing a complete event.",
    thisEventIsComplete: "This event is complete.",
    unableToEdit: "Unable to edit",
    goToAenHub: "Go to AEN Hub",
    onceYouHaveCompletedAllRequiredElementsOfTheTrainingYouWillReceiveAnEmailFromCredlyInvitingYouToClaimYourDigitalBadgeTheBadgeWillAllowYouToEasilyShareYourAchievementWithColleaguesManagersAndYourProfessionalNetworkOnLinkedInAndOtherPlatforms:
        "Once you have completed all required elements of the training, you will receive an email from Credly inviting you to claim your digital badge. The badge will allow you to easily share your achievement with colleagues, managers, and your professional network on LinkedIn and other platforms.",
    weHaveIntegratedWithCredlyToTranslateTheLearningOutcomesYouveDemonstratedIntoABadgeIssuedAndManagedThroughTheCompanyDigitalBadgingPlatformTheTechnologyCredlyUsesIsBasedOnTheOpenBadgeStandardsMaintainedByIMSGlobalThisEnablesYouToManageShareAndVerifyYourCompetenciesDigitally:
        "We have integrated with Credly to translate the learning outcomes you've demonstrated into a badge, issued and managed through the company digital badging platform. The technology Credly uses is based on the Open Badge Standards maintained by IMS Global. This enables you to manage, share, and verify your competencies digitally.",
    changesToContentWillBeAppliedToAllLearnersOnThisVersionRegardlessOfCompletionStatusAndWillNotImpactOrResetALearnersProgress:
        "Changes to content will be applied to all learners on this version regardless of completion status and will not impact or reset a learner's progress.",
    youCanOnlyMakeChangesToTheProductsContent:
        "You can only make changes to the product's content.",
    changesWillNotBeAppliedToInstructorsCurrentlyAssignedToThisProductYouWillNeedToManuallyReassignThisTrainingToTheInstructor:
        "Changes will not be applied to instructors currently assigned to this product. You will need to manually reassign this training to the instructor.",
    allCurrentlyEnrolledInstructorsWillRemainOnTheCurrentVersionToMigrateThemToTheNewVersionManuallyReassignThisTraining:
        "All currently enrolled instructors will remain on the current version.  To migrate them to the new version, manually reassign this training.",
    applicationSubmittedDescription:
        "Your application has been received. NFPA will soon begin the review process.",
    underReviewDescription:
        "The details of your application are currently under review. NFPA will contact you if more information is required.",
    pendingAssessmentDescription:
        "You have been assigned the assessment(s) for the requested course(s) on your application. You must pass the assessment(s) within three attempts and receive a minimum score of 80% to move to the final review stage.",
    finalReviewInProgressDescription:
        "Your application and video submission are in the final review stage. You will be notified once a determination is made on your approval.",
    finalReviewInProgress: "Final Review in Progress",
    thereWasAnIssueReturningTheInstructorApplicationStatus:
        "There was an issue returning the instructor application status.",
    thereWasAProblemReturningTheInstructorApplicationEnrollments:
        "There was a problem returning the instructor application enrollments.",
    applicationSubmitted: "Application Submitted",
    requiredAssessment: "Required Assessment",
    myApplicationStatus: "My Application Status",
    pendingApproval: "Pending Approval",
    instructorStatus: "Instructor Status",
    instructorAssessment: "Instructor Assessment",
    forInvoiceStatus: "For invoice status...",
    invoiced: "Invoiced",
    invoiceStatus: "Invoice Status",
    invoiceStatusType: "Invoice Status Type",
    notInvoiced: "Not Invoiced",
    notInvoicedAndInvoiced: "Not Invoiced & Invoiced",
    aProviderAlreadyExistsWithThisNetsuiteId: "A Provider Already Exists With This Netsuite ID",
    enterNetsuiteId: "Enter Netsuite Id",
    netsuiteId: "Netsuite Id",
    messageToApplicant: "Message to Applicant",
    messageSentToApplicant: "Message Sent to Applicant",
    sendMessageToApplicant: "Send Message to Applicant",
    emailBody: "Email Body",
    applicationHasBeenUpdatedSuccessfully: "Application has been updated successfully.",
    thereWasAnIssueUpdatingTheApplication: "There was an issue updating the application.",
    updateStatus: "Update Status",
    reviewer: "Reviewer",
    applicationSuccessfullyUpdated: "Application Successfully Updated",
    applicationUpdateFailed: "Application Update Failed",
    reviewCompleteAccepted: "Review Complete - Accepted",
    reviewCompleteDeclined: "Review Complete - Declined",
    hasbeeninvoiced: "Has Been Invoiced",
    eventInvoicing: "Event Invoicing",
    enterNotes: "Enter Notes",
    activityBy: "Activity By",
    addNotes: "Add Notes",
    addNote: "Add Note",
    notes: "Notes",
    thereWasAnIssueReturningActivityLogs: "There was an issue returning activity logs.",
    thereWasAnIssueSavingTheNotes: "There was an issue saving the notes.",
    noteHasBeenAddedSuccessfully: "Note has been added successfully.",
    resume: "Resume",
    thereWasAnIssueReturningApplicationFiles: "There was an issue returning application files.",
    thereWasAnIssueSavingTheFile: "There was an issue saving the file.",
    trainingSpace: "Training Space",
    fileTypeSelection: "File Type Selection",
    enterDescription: "Enter Description",
    addFile: "Add File",
    uploadDate: "Upload Date",
    uploadedBy: "Uploaded By",
    fileType: "File Type",
    addNewFile: "Add New File",
    fileUploads: "File Uploads",
    linkedInProfile: "LinkedIn Profile",
    currentNfpaMember: "Are you currently an NFPA member?",
    nfpaTechnicalCommitteeMember: "Are you currently an NFPA Technical Committee member?",
    ifYesWhichOnes: "Which Technical Committees?",
    areYouCurrentlyAnApprovedNfpaInstructor: "Are you currently an Approved NFPA Instructor?",
    haveYouPreviouslyBeenAnApprovedNfpaInstructor:
        "Have you previously been an approved NFPA instructor?",
    whyDoYouWantToBecomeAnNfpaInstructor: "Why do you want to become an NFPA instructor?",
    areYouInterestedInDeliveringVirtualTraining:
        "Are you interested in delivering virtual training",
    doYouHaveExperienceDeliveringTrainingBasedOnNfpaCodesAndStandards:
        "Do you have experience delivering training based on NFPA codes and standards?",
    ifYesPleaseBrieflyDescribeYourExperienceEGTopicsAudienceClassSize:
        "If yes, please briefly describe your experience (e.g., topics, audience, class size)",
    doYouHaveExperienceDeliveringVirtualTraining:
        "Do you have experience delivering virtual training?",
    ifYesPleaseBrieflyDescribeYourExperienceAndSkillComfortLevelDoingSo:
        "If yes, please briefly describe your experience and skill/comfort level doing so.",
    ifYesToWhere: "If yes, to where?",
    whichTopicAreasAreYouInterestedInTeaching: "Which topic areas are you interested in teaching?",
    pleaseListYourQualifications: "Please list your qualifications",
    educationDegrees: "Education/Degrees",
    certificationsLicenses: "Certifications/Licenses",
    listRelevantPositionsPositionRoleDates: "List relevant positions (position/role + dates)",
    pleaseProvideContactInformationForAtLeastTwoProfessionalReferencesWithKnowledgeOfYourSubjectMatterExpertiseAndOrTeachingAbility:
        "Please provide contact information for at least two professional references with knowledge of your subject matter expertise and/or teaching ability.",
    teachingModalities: "Teaching Modalities",
    teachingExperience: "TeachingExperience",
    interestedTopicAreas: "Interested Topic Areas",
    qualifications: "Qualifications",
    languages: "Languages",
    references: "References",
    video: "Share URL of Instruction",
    ifYesWhichCourses: "If yes, which courses?",
    applicationDetails: "Application Details",
    files: "Files",
    activityLogs: "Activity Logs",
    partIProviderInformation: "Part I: Provider Information",
    website: "Website",
    providerType: "Provider Type",
    providerBusinessSegment: "Provider Business Segment",
    whyDoYouWantToHost: "Why do you want to host?",
    locations: "Locations",
    partIITrainingAdministration: "Part II: Training Administration",
    isThereSomeoneElseBesidesTheProviderPrimaryContactWhoWillBeResponsibleForAdministratingNfpaTrainingEvents:
        "Is there someone else besides the provider primary contact who will be responsible for administrating NFPA Training events?",
    ifYesPleaseProvideFullNameAndEmail: "If yes, please provide full name and email address.",
    partIIITrainingSpace: "Part III: Training Space",
    partIVMarketing: "Part IV: Marketing",
    asAProviderHowWillYouPromoteTrainingCourses:
        "As a provider, how will you promote training courses?",
    partVCertification: "Part V: Certification",
    signature: "Signature",
    title: "Title",
    thereWasAnIssueLoadingProviderApplications: "There was an issue loading provider applications.",
    thereWasAnIssueLoadingInstructorApplications:
        "There was an issue loading instructor applications.",
    yourTrialWillBeAvailableOnTheFirstDayOfTheEvent:
        "Your trial will be available on the first day of the event.",
    whatAreTheDigitalBadgeTermsOfUse: "What are the digital badge terms of use?",
    continueToLink: "Continue to LiNK",
    yourTrialHasAlreadyBeenClaimedDoYouWantToGoThereNow:
        "Your trial has already been claimed. Do you want to go there now?",
    yourTrialHasBeenClaimedSuccessfullyDoYouWantToGoThereNow:
        "Your trial has been claimed successfully. Do you want to go there now?",
    itLooksLikeYouAlreadyHaveAnActiveLinkSubscriptionDoYouWantToGoThereNow:
        "It looks like you already have an active LiNK subscription. Do you want to go there now?",
    joinLink: "Join LiNK",
    exploreLink: "Explore LiNK",
    joinTodayToGetInstantAccessToOver1400NfpaCodesAndStandards:
        "Join today to get instant access to over 1,400 NFPA® codes and standards.",
    theresMoreToExploreWithLink: "There's more to explore with LiNK",
    youStillHaveInstantAccessToOver1400NfpaCodesAndStandards:
        "You still have instant access to over 1,400 NFPA® codes and standards.",
    numDaysOfFreeAccessRemaining: "{{num}} days of free access remaining",
    newUsersGetInstantAccessToOver1400NfpaCodesAndStandards:
        "New users get instant access to over 1,400 NFPA® codes and standards.",
    freeAccessForNumberDays: "Free access for {{number}} days",
    youMustManuallyDeleteTheExistingPackageBeforeReplacingIt:
        "You must manually delete the existing package {{externalScormCourse}} before replacing it.",
    searchByNameEmailOrLocation: "Search by name, email, or location",
    refreshing: "Refreshing",
    refreshingInNSeconds: "Refreshing in {{timeInSeconds}} seconds",
    accessingYourCertificateAndBadge: "Accessing Your Certificate and Badge",
    launchAnInstructorLedTraining: "Launch an Instructor-Led Training",
    launchAnOnlineLearning: "Launch an Online Learning",
    dashboardNavigationAndProfile: "Dashboard, Navigation, and Profile",
    accessingYourTraining: "Accessing Your Training",
    comingSoon: "Coming Soon",
    clearYourInternetBrowsersCacheAndCookiesReloadTheLearningPortalAndTryToAccessYourTrainingAgainIfThisDoesNotWorkPleaseContactNFPACustomerServiceBySelectingTheSubmitAQuestionButtonOnThisScreenOrCallingCustomerServiceDirectlyAtClientAdmin:
        "Clear your internet browser's cache and cookies. Reload the Learning Portal and try to access your training again. If this does not work, please contact NFPA customer service by selecting the Submit a Question button.",
    changesToCoursesAreNotAvailableWhenEditingAnActiveProductVersionToChangeCourses:
        "Changes to Courses are not available when editing an active product version. To change Courses,",
    changesToAssessmentsAreNotAvailableWhenEditingAnActiveProductVersionToChangeAssessments:
        "Changes to Assessments are not available when editing an active product version. To change Assessments,",
    approved: "Approved",
    failedToUnpublish: "Failed to unpublish.",
    unpublishedSuccessfully: "Unpublished Successfully",
    unpublishProduct: "Unpublish Product",
    unpublishCourse: "Unpublish Course",
    pleaseDoNotMakeChangesToTheFormatOfThisTemplateOrItWillAffectTheUploadOfTheRegistrations:
        "Please do not make changes to the format of this template or it will affect the upload of the registrations.",
    importingACSVFileIsAnEasyWayToBulkEnrollMultipleLearnersAtOnceToGetStarted:
        "Importing a CSV file is an easy way to bulk enroll multiple learners at once. To get started, you must ",
    emailsMustBeUniqueToTheIndividualRegistrant:
        "Emails must be unique to the individual registrant.",
    orderInfo: "Order Info",
    invalidData: "Invalid Data",
    productNotFound: "Product Not Found",
    thereWasAnIssueLoadingCatalogEnrollmentLogs:
        "There was an issue loading Catalog Enrollment Logs.",
    noInvalidAttendees: "No Invalid Attendees",
    noValidAttendees: "No Valid Attendees",
    errors: "Errors",
    invalidAttendees: "Invalid Attendees",
    validAttendees: "Valid Attendees",
    errorParsingCSV: "Error parsing CSV.",
    catalogEnrollmentLog: "Catalog Enrollment Log",
    saveValidAttendees: "Save Valid Attendees",
    past: "Past",
    hideLocationInformation: "Hide Location Information",
    datePickerDateFormat: "MM/dd/yyyy",
    manageEnrollments: "Manage Enrollments",
    anOrganizationAlreadyExistsWithThisNetSuiteCustomerId:
        "An organization already exists with this NetSuite Customer ID.",
    copiedToClipboard: "Copied to clipboard",
    claimNow: "Claim Now",
    freeAccessForNumDays: "Free access for {{trialDays}} days",
    useCode: "Use code ",
    toGetInstantAccessToLiNK: " to get instant access to over 1,400 NFPA® codes and standards.",
    techReview: "Tech Review",
    pendingAssessment: "Pending Assessment",
};

const providedEnglishTranslations = {
    SelectAnAENProviderIfTheInstructorIsAssociatedWithAnExistingAENProviderEgTheInstructorIsAnEmployeeOfTheAENProviderOrganization:
        "Select an AEN Provider if the instructor is associated with an existing AEN Provider (e.g., the instructor is an employee of the AEN Provider organization)",
    aenApplications: "AEN Applications",
    aGroup: "A Group",
    aProviderAlreadyExistsWithThisName: "A Provider Already Exists With This Name",
    aProviderAlreadyExistsWithThisNfpaId: "A Provider Already Exists With This NFPA ID",
    addGroup: "Add Group",
    addGroups: "Add Groups",
    addNewContent: "Add New Content",
    addNewGroup: "Add New Group",
    addProductApproval: "Add Product Approval",
    addUsers: "Add Users",
    aenProductCatalog: "AEN Product Catalog",
    aenResources: "AEN Resources",
    allEvents: "All Events",
    approvedToTeachProductCreatedSuccessfully: "Approved to Teach product created successfully.",
    approvedToTeachProductRemovedSuccessfully: " Approved to Teach product removed successfully.",
    areYouSureYouWantToRemoveContent: "Are you sure you want to remove {{content}}?",
    areYouSureYouWantToRemoveThisUserFromThisGroup:
        "Are you sure you want to remove {{user}} from {{group}}?",
    areYouSureYouWantToRevokeApproval:
        "Are you sure you want to revoke {{instructorName}}'s approval to teach {{productName}}?",
    assignedTo: "Assigned to",
    associateThisUserToAnExistingENProvider: "Associate this user to an existing AEN Provider",
    associatedENProvider: "Associated AEN Provider",
    authorizedEducationNetworkTrainings: "Authorized Education Network Trainings",
    availableForAEN: "Available For AEN",
    backToAllProducts: "Back to All Products",
    beforeBuildingYourSchedule: "before building your schedule.",
    bySelectingAnAENProviderTheInstructorCanStillBeAssignedToEventsForTheAssociatedAENProviderEvenIfTheyAreExcludedFromTheAENProviderDirectoryAndSearch:
        "By selecting an AEN Provider,  the instructor can still be assigned to events for the associated AEN Provider even if they are excluded from the AEN Provider directory and search",
    calendarView: "Calendar View",
    certificates: "Certificates",
    changesToAssessmentsAreNotAvailableWhenEditingTheCurrentProductVersionToChangeAssessmentsCreateANewProductVersion:
        "Changes to Courses are not available when editing the current course version. To change Courses,  create a new product version.",
    changesToBadgesAndCertificatesWillBeAppliedToAllLearnersOnThisVersionRegardlessOfCompletionStatusAndWillNotImpactOrResetALearnersProgress:
        "Changes will be applied to all Learners on this version,  regardless of completion status,  and will not impact or reset a Learner's progress.",
    changesToCoursesAreNotAvailableWhenEditingTheCurrentProductVersionToChangeCourses:
        "Changes to Courses are not available when editing the current product version. To change Courses,",
    clientTrainingAdmin: "Client Training Admin",
    companyName: "Company Name",
    completeCoursesInOrder: "Complete courses in order",
    contactEmail: "Contact Email",
    contentCreatedSuccessfully: "Content created successfully.",
    contentDeletedSuccessfully: "Content deleted successfully.",
    contentForInstructors: "Content for Instructors",
    contentForProviders: "Content for Providers",
    contentUpdatedSuccessfully: "Content updated successfully.",
    courseObjectives: "Course Objectives",
    courseObjectivesInclude: "Course objectives include,",
    createSomethingNew: "Create Something New",
    creatingGroupUsersFailed: "Creating group users failed.",
    creatingaenresourcefailed: "creatingAenResourceFailed",
    displaysTrainingsWhereAccessHasExpiredOrTrainingRequirementsAreComplete:
        "Displays trainings where access has expired or training requirements are complete.",
    download: "Download",
    downloadAndPrint: "Download & Print",
    downloadButtonForResourceResourceName: "Download button for resource {{resourceName}}",
    editGroup: "Edit Group",
    emailManager: "Email Manager",
    emailSubject: "Email Subject",
    enProviderAdmin: "AEN Provider Admin",
    enProvidersNeedToBeAddedBeforeTheyCanBeAssignedToAUser:
        "AEN Providers need to be added before they can be assigned to a user.",
    enrollmentForTrainingAlreadyExists: "Enrollment for training already exists.",
    enterGroupDescription: "Enter Group Description",
    enterGroupName: "Enter Group Name",
    enterLongDescription: "Enter long description",
    enterNetSuiteCustomerID: "Enter NetSuite Customer ID",
    errorFetchingProducts: "Error fetching products",
    eventAttendees: "Event Attendees",
    eventViewType: "Event View Type",
    excludeFromAenProviderDirectoryAndSearch: "Exclude from AEN Provider Directory and Search",
    failedToActivateProductVersion: "Failed to activate Product Version.",
    failedtoupdateaenresource: "Failed to update AEN resource.",
    filterByAssignedTo: "Filter by Assigned To",
    filterByAvailableForAEN: "Filter By Available For AEN",
    filterByType: "Filter By Type",
    goToApplication: "Go to Application",
    goToYourDashboard: "Go to Your Dashboard",
    group: "Group",
    groupDescription: "Group Description",
    groupHasBeenAddedSuccessfully: "Group Has Been Added Successfully",
    groupHasBeenUpdatedSuccessfully: "Group Has Been Updated Successfully",
    groupInformation: "Group Information",
    groupName: "Group Name",
    groups: "Groups",
    hasAssessment: "Has Assessment",
    iWantToRunAnAllUserReportByThisUserRole:
        "I want to run an All User report by this user role/group...",
    iWantToRunAnEnrollmentReportBasedOn: "I want to run an enrollment report based on...",
    iWantToSendAnEmailTo: "I want to send an email to,",
    ifTheEventHoursWillExceedTheDefaultCEUValueTheCEUAmountMayBeAdjustedCommonValidReasonsTheEventMayRunLongerInclude:
        "Each hour of class time equals 0.1 CEUs. If the event CEUs will exceed the default CEU value, the CEU amount may be adjusted. Common valid reasons the event may run longer include,",
    instructorRoleWasAddedButSavingInstructorInformationFailed:
        "Instructor role was added, but saving instructor information failed.",
    intendedAudience: "Intended Audience",
    lastLoginDate: "Last Login Date",
    linkTrialId: "Trial ID",
    linkTrialInformationWithHTML: `NFPA LiNK<sup>${UnicodeCharacterConstants.Registered}</sup> Trial Information`,
    linkTrialToken: "Trial Token",
    listView: "List View",
    longDescription: "Long Description",
    underReview: "Under Review",
    contractInProgress: "Contract in Progress",
    declined: "Declined",
    creditCheckInProgress: "Credit Check in Progress",
    moreInfoNeeded: "More Info Needed",
    nameOfGroup: "Name of Group",
    nameOfOrganization: "Name of Organization",
    netsuiteCustomerID: "NetSuite Customer ID",
    newGroup: "New Group",
    nfpaWebId: "NFPA Web ID",
    noAssessmentsAdded: "No Assessment(s) Added",
    noCertificateResultsFound: "No Certificate Results Found",
    noContentResultsFound: "No Content Results Found",
    noResourcesFound: "No Resources Found",
    notAvailableForAen: "Not Available For Aen",
    optionalAenProviderAssociationForInstructor: "Optional AEN Provider Association for Instructor",
    pleaseAdjustYourScheduleOrContactNFPAForApproval:
        "Please adjust your CEUs or contact NFPA for approval.",
    problemLoadingProvider: "Problem loading provider",
    productCatalog: "Product Catalog",
    productImage: "Product Image",
    productVersionIsNowActive: "Product Version is now active.",
    providerHasBeenAddedSuccessfully: "Provider Has Been Added Successfully",
    providerHasBeenUpdatedSuccessfully: "Provider Has Been Updated Successfully",
    providers: "Providers",
    recipientType: "Recipient Type",
    removeUser: "Remove User",
    requirePassed: "Require Passed",
    revokeApproval: "Revoke Approval",
    revoked: "Revoked",
    searchByCourseNameOrID: "Search By Course Name or ID",
    searchByProductNameOrID: "Search By Product Name or ID",
    selectAGroup: "Select a Group",
    selectATemplate: "Select a Template",
    selectAnEvent: "Select an Event",
    selectGroups: "Select Groups",
    sendAnEmail: "Send an Email",
    sendEmail: "Send Email",
    includeAenNetwork: "Include Authorized Education Network",
    tCreditHourEqualstCEUs: "{{creditHour}} Credit Hour = {{ceu}} CEU",
    theRecomendedCEUsForSelectedProductIs:
        "The recommended CEUs for selected product is {{productCEUs}}.",
    theRecommendedCEUsForThisEventIsProductCEUsHoursAndShouldNotExceedMaxCEUs:
        "The recommended CEUs for this event are {{productCEUs}} CEUs and should not exceed {{maxCEUs}} CEUs.",
    theStatusAsAnActiveAenProviderIsContingentUponTheOrganizationsComplianceWithTheAenContractTimelyPaymentsAndFavorableCustomerEvaluations:
        "The status as an active AEN provider is contingent upon the organization's compliance with the AEN contract,  timely payments, and favorable customer evaluations.",
    thereWasAProblemSendingTheEmail: "There was a problem sending the email.",
    thereWasAProblemSyncingTheUser: "There was a problem syncing the user.",
    thereWasAnErrorCreatingTheContent: "There was an error creating the content.",
    thereWasAnErrorSavingTheContent: "There was an error saving the content.",
    thereWasAnIssueCreatingTheApprovedToTeachProduct:
        "There was an issue creating the approved to teach product.",
    thereWasAnIssueCreatingTheGroup: "There was an issue creating the group",
    thereWasAnIssueCreatingTheProvider: "There was an issue creating the provider",
    thereWasAnIssueDeletingTheContent: "There was an issue deleting the content",
    thereWasAnIssueLoadingAenResources: "There was an issue loading AEN Resources",
    thereWasAnIssueLoadingGroupUsers: "There was an issue loading group users",
    thereWasAnIssueLoadingGroups: "There was an issue loading groups.",
    thereWasAnIssueLoadingTheGroup: "There was an issue loading the group.",
    thereWasAnIssueLoadingTheInstructorsApprovedToTeachProducts:
        "There was an issue loading the instructor's Approved to Teach products.",
    thereWasAnIssueLoadingUsers: "There was an issue loading users.",
    thereWasAnIssueRemovingTheApprovedToTeachProduct:
        "There was an issue removing the approved to teach product.",
    thereWasAnIssueRemovingTheUser: "There was an issue removing the user.",
    thereWasAnIssueSavingTheGroup: "There was an issue saving the group.",
    thereWasAnIssueUpdatingTheGroup: "There was an issue updating the group",
    thereWasAnIssueUpdatingTheProvider: "There was an issue updating the provider",
    therewasanissuesavingthecontent: "There was an issue saving the content.",
    therewasaproblemcreatinganewenrollment: "There was a problem creating a new enrollment.",
    theseEventsAreForTheAENOnlyAndRunByTheAENProvider:
        "These events are for the AEN only and run by the AEN provider",
    thisContactInformationWillBeAvailableToEventParticipantsAsThePrimaryMethodToContactTheEventOrganizerWhenUseDefaultDuringTheEventCreationProcess:
        'This contact information will be available to event participants as the primary method to contact the event organizer when "Use Default" is selected during the event creation process.',
    tileView: "Tile View",
    toCreateAnEventForAnotherProductGoToEventsAndSelectNewEvent:
        "To create an event for another product,  go to Events and select 'New Event'.",
    trainingViewType: "Training View Type",
    typeToSearchByInstructorName: "Type to search by instructor name",
    typeToSearchByNameOrEmail: "Type to search by name or email",
    updateAenResourceFailed: "Updating AEN resource failed.",
    updateaenresourcesuccessful: "Update AEN resource successful.",
    uploadAProductImage: "Upload a Product Image",
    useThisToggleToSettoAllDAyWhenSessionTimesAreUnknown:
        "Use this toggle to set to all day when session times are unknown.",
    user: "User",
    userId: "User ID",
    userName: "User Name",
    userRemovedSuccessfully: "User removed successfully.",
    userWasUndefined: "User was undefined",
    viewGroup: "View Group",
    whatDoYouWantToSay: "What do you want to say?",
    whoWillBenefit: "Who will benefit,",
    youAreCreatingAnEventForPropsProductName: "You are creating an event for {{propsProductName}}",
    youAreCurrentlyNotApprovedToTeachAnyTrainings:
        "You are currently not approved to teach any trainings.",
    youHaveNoRequiredTrainings: "You Have No Required Trainings.",
    yourContactInformationWillComeDirectlyFromYourNFPAProfileYourContactInformationWillBeAvailableToLMSAdministratorsForSchedulingPurposesItWillAlsoBeVisibleToEnrolledLearnersForEventsInWhichYouAreTheInstructor:
        "Your contact information will come directly from your NFPA profile. Your contact information will be available to Learning Portal administrators for scheduling purposes. It will also be visible to enrolled learners for events in which you are the instructor.",
    yourEmailHasBeenSent: "Your email has been sent.",
    yourLocationInformationWillComeDirectlyFromYourNFPAProfileIfYouWouldLikeYourLocationInformationVisibleToAENProvidersPleaseMoveTheToggleToTheRightToTurnOnTheShareMyLocationInformationFeature:
        'Your location information will come directly from your NFPA profile. If you would like your location information visible to AEN providers,  please move the toggle to the right to turn on the "Share My Location Information" feature.',
    yourOrganizationHasNoPastOnlineTrainings: "Your Organization Has No Past Online Trainings",
    yourReportIsReady: "Your report is ready",
    youveBeenGrantedAnExceptionToExceedMaxCEUsHoursForYourEvent:
        "You've been granted an exception to exceed {{maxCEUs}} CEUs for your event.",

    instructorsFAQs: "Instructors FAQs",
    AENProviderFAQ: "AEN Provider FAQ",
    allCourseMaterialsWillBeAvailableDigitallyToParticipantsAndTheInstructorOnTheLMS:
        "All course materials will be available digitally to participants and the instructor on the Learning Portal.",
    aPastTrainingIsATrainingEventWhereAllOfTheEventDatesareInThePast:
        "A past training is a training event where all of the event dates are in the past.",
    areYouSureYouWouldLikeToLeaveWithoutCreatingANewVersion:
        "Are you sure you would like to leave without creating a new version?",
    badgeFAQs: "Badge FAQs",
    buildingLifeSafety: "Building & Life Safety",
    byDeactivatingThisCourseItWillNoLongerBeAvailableToAddToAProductTheCourseWillStillAppearInTheListViewOfCourses:
        "By deactivating this course it will no longer be available to add to a product. The course will still appear in the list view of courses.",
    canIaddmoreparticipantstotheclassroster: "Can I add more participants to the class roster?",
    changesToCEUsWillBeAppliedToTheRecommendedClassTimeForAnEventAndWillNotImpactOrResetTheEventScheduleForPublishedOrDraftEvents:
        "Changes to CEUs will be applied to the recommended class time for an Event and will not impact or reset the Event Schedule for Published or Draft Events.",
    changesToContentBadgesAndCertificatesWillBeAppliedToAllLearnersOnThisVersionRegardlessOfCompletionStatusAndWillNotImpactOrResetALearnersProgress:
        "Changes to Content, Badges and Certificates will be applied to all Learners on this version, regardless of completion status, and will not impact or reset a Learner's progress.",
    changesToTheEvaluationAreNotAvailableFollowingInitialCreationOfTheProduct:
        "Changes to the Evaluation are not available following initial creation of the product.",
    clearYourInternetBrowsersCacheAndCookiesReloadTheLMSAndTryToAccessYourTrainingAgainIfThisDoesNotWorkPleaseContactNFPACustomerServiceBySelectingTheSubmitAQuestionButtonOnThisScreenOrCallingCustomerServiceDirectlyAt:
        "Clear your internet browser's cache and cookies. Reload the Learning Portal and try to access your training again. If this does not work, please contact NFPA customer service by selecting the Submit a Question button.",
    clickContinueToCreateANewVersionThisActionWillReturnYouToTheProductVersionListWhereYouCanActivateAndDetermineHowToApplyThisNewVersionToInProgressLearners:
        "Click Continue to create a new version. This action will return you to the Product Version list where you can activate and determine how to apply this new version to In Progress Learners.",
    clientAdminFAQ: "Client Admin FAQs",
    completeAssessmentsInOrder: "Complete assessments in order",
    courseseries: "Course/Series",
    deactivatingThisProductWillBeReflectedONLYWithinTheLMSBeSureToUpdateAllAssociatedSystemsIncludingTheNFPACatalogOnceDeactivatedThisProductWillNoLongerBeAvailableForNewEnrollmentsOrContracts:
        "Deactivating this Product will be reflected ONLY within the Learning Portal. Be sure to update all associated systems, including the NFPA Catalog. Once deactivated, this Product will no longer be available for new Enrollments or Contracts.",
    emergingTechnology: "Emerging Technology",
    erpId: "ERP ID",
    fireProtection: "Fire Protection Systems",
    howChangesAreApplied: "How Changes are Applied",
    ifTheCertificateIsSpecificallyForHotWorkSelectYes:
        "If the certificate is specifically for Hot Work, select 'Yes'.",
    industrialChem: "Industrial Hazards",
    learnerFAQs: "Learner FAQs",
    LocateTheEventFromEitherTheEventCalendarOrTheEventsQuickLinkFromYourDashboardIfLocatingFromTheEventCalendarSelectTheSpecificEventAndThenSelectTheEditEventButtonThatWillAppearOnTheRighthandSideOfTheCalendarIfLocatingFromTheEventsQuickLinkUseTheEllipsisAndChooseEditEventNavigateThroughTheEventToEditAsNecessaryOrSelectTheCancelEventButtonAtTheBottomOfTheLefthandSidePaneAllLearnersAndInstructorsWillAutomaticallyReceiveBannerUpdatesConcerningTheChangesOrCancellation:
        "Locate the event from either the Event Calendar or the Events quick link from your dashboard. If locating from the event calendar, select the specific event and then select the Edit Event button that will appear on the right-hand side of the calendar. If locating from the events quick link, use the ellipsis and choose Edit Event. Navigate through the event to edit as necessary or select the Cancel Event button at the bottom of the left-hand side pane. All learners and instructors will automatically receive banner updates concerning the changes or cancellation.",
    newAndDraftEventsWillReceiveThisNewVersion:
        "New and Draft Events will receive this new version.",
    newlyPublishedEventsWillReceiveTheLatestActiveILTProductVersion:
        "Newly Published Events will receive the latest, active ILT Product version.",
    NoAllCourseMaterialsHaveBeenReviewedByNFPATechnicalStaffIfYouHaveFeedbackRegardingTheCourseMaterialsPleaseContactYourSalesRepresentative:
        "No, all course materials have been reviewed by NFPA technical staff.",
    noContentAdded: "No Content Added",
    notAtThisTime: "Not at this time.",
    noThisIsAServiceWeProvideToYouAtNoCost: "No. This is a service we provide to you, at no cost.",
    onceAnEventIsPublishedItIsTiedToAnILTProductVersionAndWillRemainOnThatVersion:
        "Once an Event is Published, it is tied to an ILT Product version and will remain on that version.",
    onceAnEventIsPublishedItIsTiedToAnILTProductVersionAndWillRemainOnThatVersionRegardlessOfScheduleOrCompletionStatus:
        "Once an Event is Published, it is tied to an ILT Product version and will remain on that version regardless of schedule or completion status.",
    onceAnEventIsPublishedItIsTiedToAnILTProductVersionAndWillRemainOnThatVersionRegardlessOfScheduleOrCompletionStatusNewlyPublishedEventsWillReceiveTheLatestActiveILTProductVersion:
        "Once an Event is Published, it is tied to an ILT Product version and will remain on that version regardless of schedule or completion status. Newly Published Events will receive the latest, active ILT Product version.",
    ourPrimaryContactIsYourNFPASalesRepresentative:
        "Our primary contact is your NFPA sales representative.",
    PleaseContactYourNFPASalesRepresentative: "Please contact your NFPA Sales Representative.",
    pleaseContactNFPACustomerServiceBySelectingTheSubmitAQuestionButtonOnThisScreenOrCallingCustomerServiceDirectlyAt:
        "",
    PleaseContactNFPACustomerServiceBySelectingTheSubmitAQuestionButtonOnThisScreenOrCallingCustomerServiceDirectlyAt:
        "Please contact NFPA customer service by selecting the Submit a Question button.",
    pleaseContactTheProviderOfTheEventForTechnologyIssuesDuringClass:
        "Please contact the provider of the event for technology issues during class.",
    PleaseWorkWithYourNFPASalesRepresentativeToProvideTheDetailsOfAnyTrainingYouWouldLikeScheduled:
        "Please work with your NFPA sales representative to provide the details of any training you would like scheduled.",
    publicEducation: "Public Education",
    selectNoIfTheTrainingDoesNotHaveAnExpirationDateANoWillDisplayAnNAInTheCertificateExpirationDateField:
        "Select 'No' if the training does not have an expiration date. A 'No' will display an 'N/A' in the certificate expiration date field.",
    selectYesIfTheTrainingHasAnExpirationDateAYesWillDisplayACertificateExpirationDateCalculatedBasedOnTheTrainingCompletionDateAndTheNumberOfYearsInputInTheHowLongIsTheCertificateValidField:
        "Select 'Yes' if the training has an expiration date. A 'Yes' will display a certificate expiration date calculated based on the training completion date and the number of years input in the 'How long is the certificate valid' field.",
    takeAMomentToReviewYourNewVersionAndPopulateTheChangeLogBeforeActivatingIfYouWouldLikeToMakeAnyChangesReturnToTheAppropriatePageOfTheJourney:
        "Take a moment to review your new version and populate the change log before activating. If you would like to make any changes, return to the appropriate page of the journey.",
    theSelectedILTProductIsNoLongerAvailablePleaseSelectANewILTProductToPublishYourEvent:
        "The selected ILT Product is no longer available. Please select a new ILT Product to publish your Event.",
    thisProductHasNoContent: "This product has no content",
    wildfire: "Wildfire",
    YesFromTheCurrentTrainingsOnTheDashboardSelectEitherTheOnlineOrInstructorLedTabSelectViewTrainingFromTheAppropriateCourseNavigateToTheEnrollmentSectionFromTheLeftSidePaneSelectEitherTheAddNewButtonOrTheImportCSVButtonToAddParticipantsToTheRoster:
        "Yes. From the Current Trainings on the dashboard, select either the Online or Instructor-Led tab. Select View Training from the appropriate course. Navigate to the Enrollment section from the left-hand pane. Select either the Add New button or the Import CSV button to add participants to the roster.",
    yesHoweverWeCannotGuaranteeTheInstructorsAvailability:
        "Yes, however, we cannot guarantee the instructor's availability.  Please work with your NFPA Sales representative.",
    yesPleaseWorkWithYourNFPASalesRepresentativeToOrderPhysicalMaterialsForTheTraining:
        "Yes, please work with your NFPA sales representative to order physical materials for the training.",
    YesSummaryResultsOfTheCourseEvaluationsForInstructorledTrainingAreProvidedRealTimeSelectViewTrainingFromEitherCurrentUpcomingTrainingsOrFromTheEllipsisIconUnderPastTrainingsOnceYouAreViewingTheDesiredTrainingTheEvaluationSectionCanBeAccessedUsingTheLefthandPane:
        "Yes, summary results of the course evaluations for instructor-led training are provided in real time. Select View Training from either Current & Upcoming Trainings or from the ellipsis icon under Past Trainings. Once you are viewing the desired training, the Evaluation section can be accessed using the left-hand pane.",
    YesYouCanViewOverallAssessmentDataForAClassHoweverYouCannotSeeIndividualLearnerResultsSelectViewTrainingFromEitherCurrentUpcomingTrainingsOrFromTheEllipsisIconUnderPastTrainingsOnceYouAreViewingTheDesiredTrainingTheAssessmentSectionCanBeAccessedUsingTheLefthandPane:
        "Yes, you can view overall assessment data for a class. However, you cannot see individual learner results. Select View Training from either Current & Upcoming Trainings or from the ellipsis icon under Past Trainings. Once you are viewing the desired training, the Assessment section can be accessed using the left-hand pane.",
    youAreAboutToArchiveThisCourseArchivingTheCourseWillRemoveItFromTheCourseListViewUnlessTheIncludeArchivedToggleButtonIsTurnedOnInOrderToArchiveThisCourseYouNeedTo:
        "You are about to archive this course. Archiving the course will remove it from the course list view unless the 'Include Archived' toggle button is turned on. In order to archive this course you need to:",
    youAreAboutToArchiveThisProductArchivingTheProductWillRemoveItFromTheProductListViewUnlessTheIncludeArchivedToggleButtonIsTurnedOn:
        "You are about to archive this product. Archiving the product will remove it from the product list view unless the 'Include Archived' toggle button is turned on.",
    youCanConfigureYourPrivacySettingsInCredlyYoureInCompleteControlOfTheInformationAboutYourselfThatIsMadePublic:
        "You can configure your privacy settings in Credly. You're in complete control of the information about yourself that is made public.",
    youCanContinueToAccessAndUseThoseBadgesThrough:
        "You can continue to access and use those badges through",
    YourFirstPointOfContactWillBeNFPACustomerServicePleaseSelectTheSubmitAQuestionButtonOnThisScreenOrCallCustomerServiceDirectlyAt:
        "Your first point of contact will be NFPA customer service. Please select the Submit a Question button on this screen or call customer service directly at:",
    youWillReceiveAnEmailNotificationFromCredlyAdmincredlycomWithInstructionsForClaimingYourBadgeAndSettingUpYourAccount:
        "You will receive an email notification from Credly (admin@credly.com) with instructions for claiming your badge and setting up your account.",
    activateNewVersion: "Activate New Version",
    activatingProductVersionFailed: "Activating Product Version failed.",
    addALearner: "Add a Learner",
    AllInstructorledClassesProvideAccessToDigitalMaterialsTheHostOfTheEventWillDetermineIfPhysicalMaterialsWillAlsoBeProvided:
        "All instructor-led classes provide access to digital materials. The host of the event will determine if physical materials will also be provided.",
    anyUpcomingEventsUsingThePreviousVersionWillReceiveThisNewVersion:
        "Any upcoming Events using the previous version will receive this new version.",
    browseNfpaTrainingCatalog:
        "Browse the NFPA Training Catalog to find training options to increase your knowledge, gain on-the-job confidence, and improve your professional reputation.",
    byCreatingThisNewVersionLearnersWhoHaveCompletedThePreviousVersionWillRemainOnThatVersionAnyLearnersWhoHaveEnrolledInThePreviousVersionButHaveNoStartedWillReceiveThisNewVersionYouWillBeAbleToChooseHowThisVersionIsAppliedToLearnersThatAreInProgressOnTheCurrentVersion:
        "By creating this new version, learners who have completed the previous version will remain on that version. Any learners who have enrolled in the previous version but have no started will receive this new version. You will be able to choose how this version is applied to learners that are in progress on the current version.",
    canIChangeOrUpdateTheCourseMaterials: "Can I change or update the course materials?",
    canIExportBadgesIssuedThroughCredlyToOtherBadgestoringPlatforms:
        "Can I export badges issued through Credly to other badge-storing platforms?",
    canIImportBadgesIssuedFromOtherPlatformsIntoCredly:
        "Can I import badges issued from other platforms into Credly?",
    canIRequestAParticularInstructor: "Can I request a particular instructor?",
    canIRequestPhysicalMaterialsForAClass: "Can I request physical materials for a class?",
    canISeeHowParticipantsScoredOnTheAssessments:
        "Can I see how participants scored on the assessment(s)?",
    canIViewTheCourseEvaluations: "Can I view the course evaluations?",
    canMyOrganizationOrTheApprovedInstructorChangeOrUpdateTheCourseMaterials:
        "Can my organization or the approved instructor change or update the course materials?",
    changesToAssessmentsAreNotAvailableWhenEditingTheCurrentCourseVersionToChangeAssessments:
        "Changes to Assessments are not available when editing the current course version. To change Assessments,",
    changesToAssessmentsAreTheTriggerForNewVersions:
        "Changes to Assessments are the trigger for new versions.",
    changesToAssessmentsMustBeMadeInOrderToUnlockTheOtherSections:
        "Changes to Assessments must be made in order to unlock the other sections.",
    changesToAssessmentsOrEvaluationAreNotAvailableWhenEditingTheCurrentCourseVersionToChangeTheseItemsYouMustCreateANewCourseVersion:
        "Changes to Assessments or Evaluation are not available when editing the current course version. To change these items, you must create a new course version.",
    changesToCoursesAreTheTriggerForNewVersions:
        "Changes to Courses are the trigger for new versions.",
    changesToCoursesMustBeMadeInOrderToUnlockTheOtherSections:
        "Changes to Courses must be made in order to unlock the other sections.",
    changesToEvaluationAreUnavailableFollowingInitialCreationOfTheProduct:
        "Changes to Evaluation are unavailable following initial creation of the product.",
    changesToRecommendedCEUsWillOnlyBeAppliedToNewAndUnpublishedEventsAsFinalEventCEUsAreDeterminedByTheIndividualEventsSchedule:
        "Changes to recommended CEUs will only be applied to new and unpublished events, as final event CEUs are determined by the individual event's schedule.",
    changesWillBeAppliedToAllLearnersOnThisVersionRegardlessOfCompletionStatusAndWillNotImpactOrResetALearnersProgress:
        "Changes will be applied to all Learners on this version, regardless of completion status, and will not impact or reset a Learner's progress.",
    CompletedTrainingCanBeViewedOnTheMyLearningDashboardForTrainingWhereAccessHasNotExpiredViewTheAssignmentsSectionOnEitherTheOnlineOrInstructorledTabsAndSelectViewTrainingForTrainingWhereAccessHasExpiredViewThePastTrainingsListAtTheBottomOfTheAssignmentsPageAndSelectViewSummary:
        "Completed training can be viewed on the My Learning dashboard. For training where access has not expired, view the Assignments section on either the Online or Instructor-led tabs and select View Training. For training where access has expired, view the Past Trainings list at the bottom of the Assignments page and select View Summary.",
    contentBadgeAndCertificateExpirationChangesWillBeAppliedToAllLearnersOnThisVersionRegardlessOfCompletionStatusAndWillNotImpactOrResetALearnersProgress:
        "Content, Badge, and Certificate Expiration changes will be applied to all Learners on this version, regardless of completion status, and will not impact or reset a Learner's progress.",
    contentChangesWillBeAppliedToAllLearnersOnThisVersionRegardlessOfCompletionStatusAndWillNotImpactOrResetALearnersProgress:
        "Content changes will be applied to all Learners on this version, regardless of completion status, and will not impact or reset a Learner's progress.",
    createANewProductVersion: "create a new product version",
    credlyIsTheEndtoendSolutionForIssuingAndManagingDigitalCredentialsCredlyWorksWithCredibleOrganizationsToProvideDigitalCredentialsToIndividualsWorldwide:
        "Credly is the end-to-end solution for issuing and managing digital credentials. Credly works with credible organizations to provide digital credentials to individuals worldwide.",
    CurrentlyYouMayChangeTheDefaultLanguageOfMyLearningFromEnglishToSpanishOrArabicOnlyYouCanDoThisInTheMyProfilePage:
        "Currently, you may change the default language of My Learning from English to Spanish or Arabic only. You can do this in the My Profile page.",
    EachTrainingContainsACourseEvaluationWhereYouWillAnswerSpecificQuestionsAboutTheCourseAsWellAsHaveAnOpportunityToProvideOpenendedFeedback:
        "Each training contains a course evaluation where you will answer specific questions about the course as well as have an opportunity to provide open-ended feedback.",
    EachTrainingContainsACourseEvaluationWhereYouWillAnswerSpecificQuestionsAboutTheInstructorAndEventEnvironmentAsWellAsHaveAnOpportunityToProvideOpenendedFeedback:
        "Each training contains a course evaluation where you will answer specific questions about the instructor and event environment as well as have an opportunity to provide open-ended feedback.",
    editProductDetails: "Edit Product Details",
    editVersionProductVersionVersionId: "Edit Version {{productVersionVersionId}}",
    eventsThatHaveCompletedThePreviousVersionWillRemainOnThatVersion:
        "Events that have completed the previous version will remain on that version.",
    expirationDuration: "Expiration Duration",
    fromYourTeachingAssignmentsSelectTheViewTrainingButtonOnTheAboutSectionOfTheEventYouWillFindTheContactInformationForTheEventHost:
        "From your Teaching Assignments, select the View Training button. On the About section of the event, you will find the contact information for the event host.",
    fromYourTeachingAssignmentsSelectTheViewTrainingButtonOnTheEvaluationSectionOfTheEventYouWillFindASummaryOfTheEvaluationResultsParticipantsAreAskedToProvideFeedbackAboutTheInstructorOnTheFirstSixQuestionsTheSixthQuestionIsHowLikelyWouldYouBeToRecommendThisInstructorThisIsIndicativeOfYourOverallInstructorRating:
        "From your Teaching Assignments, select the View Training button. On the Evaluation section of the event, you will find a summary of the evaluation results. Participants are asked to provide feedback about the instructor on the first six questions. The sixth question is, How likely would you be to recommend this instructor? This is indicative of your overall instructor rating.",
    howCanIChangeTheDefaultLanguage: "How can I change the default language?",
    howCanIOrderPhysicalMaterialsForAnUpcomingClass:
        "How can I order physical materials for an upcoming class?",
    howCanIUpdateMyProfileInformation: "How can I update my profile information?",
    howCanIViewMyCompletedTraining: "How can I view my completed training?",
    howCanIViewprintMyCertificatesbadges: "How can I view/print my certificates/badges?",
    howDoesMyCertificationGetDisplayedAsABadge:
        "How does my certification get displayed as a badge?",
    howDoIGetAClassScheduled: "How do I get a class scheduled?",
    howDoIJoinALiveVirtualTrainingSession: "How do I join a live virtual training session?",
    howWillIKnowIfIveEarnedABadge: "How will I know if I've earned a badge?",
    howWouldYouLikeToApplyThisNewVersionToLearnersWhoAreInProgressOnTheCurrentVersionAssigningTheNewVersionWillResetTheLearnersProgressForTheCoursesThatHaveBeenEdited:
        "How would you like to apply this new version to In Progress Learners? Progress in any course in the new version will not be reset.",
    ifYouWouldLikeToApplyThisNewVersionToInprogressEventsOnlyItemsThatHaveBeenChangedWillBeEffected:
        "If you would like to apply this new version to in-progress events, only items that have been changed will be effected.",
    ifYouWouldLikeToApplyThisNewVersionToInprogressLearnersOnlyItemsThatHaveBeenChangedWillBeEffected:
        "If you would like to apply this new version to in-progress Learners, only items that have been changed will be effected.",
    iHaveAQuestionAboutCredlyWhereCanIFindSupport:
        "I have a question about Credly. Where can I find support?",
    isThereAFeeToUseCredly: "Is there a fee to use Credly?",
    laborMarketInsightsArePulledFromLiveJobRequisitionsBasedOnYourSkillsYouCanLearnWhichEmployersAreHiringWhatJobTitlesYouMightBeQualifiedForSalaryRangesAndMoreSearchActiveJobListingsAndEvenApplyForThemWithJustAFewClicksThroughCredlyAccessTheLaborMarketInsightsFromYourBadgeDetailsPageByClickingOnRelatedJobsOrByClickingOnTheSkillTagsAssignedToYourBadge:
        "Labor market insights are pulled from live job requisitions. Based on your skills you can learn which employers are hiring, what job titles you might be qualified for, salary ranges, and more. Search active job listings and even apply for them with just a few clicks through Credly. Access the labor market insights from your badge details page by clicking on Related Jobs, or by clicking on the skill tags assigned to your badge.",
    learners: "Learners",
    liveEvents: "Live Events",
    liveEventsRegardlessOfProgressAndAllPastCompletedEventsWillContinueToUseThisProduct:
        "Live Events, regardless of progress, and all Past/Completed Events will continue to use this Product.",
    liveOrPastCompletedEvents: "Live or Past/Completed Events",
    LocateYourCompletedTrainingSeeFAQHowCanIViewMyCompletedTrainingUponViewingYourCompletedTrainingNavigateToTheAchievementsSectionFromTheLefthandPaneSelectTheButtonToDownloadCertificateAndorGoToCredly:
        "Locate your completed training. See FAQ: How can I view my completed training? Upon viewing your completed training, navigate to the Achievements section from the left-hand pane. Select the button to download certificate and/or go to Credly.",
    needFurtherAssistanceWithTheLMSOrAccessingYourTraining:
        "Need further assistance with the Learning Portal or accessing your training?",
    newEvents: "New Events",
    noAllCourseMaterialsHaveBeenReviewedByNFPATechnicalStaffIfYouHaveFeedbackRegardingTheCourseMaterialsPleaseContactNFPACustomerServiceBySelectingTheSubmitAQuestionButtonOnThisScreenOrCallingCustomerServiceDirectlyAt:
        "No, all course materials have been reviewed by NFPA technical staff.",
    noContractResultsFound: "No Contract Results Found",
    noCourseResultsFound: "No Course Results Found",
    noEventResultsFound: "No Event Results Found",
    noHistoryAvailable: "No History Available",
    noInstructorResultsFound: "No Instructor Results Found",
    noLearnersEnrolled: "No Learners Enrolled",
    noProductResultsFound: "No Product Results Found",
    noTrainingResultsFound: "No training results found.",
    openBadgesAreWebenabledVersionsOfACredentialCertificationOrLearningOutcomeThatCanBeVerifiedInRealTimeOnline:
        "Open badges are web-enabled versions of a credential, certification, or learning outcome that can be verified in real time, online.",
    pastOnlineLearningTrainings: "Past Online Learning Trainings",
    pleaseContactNFPAYouCanSelectTheGoToSupportButtonOnTheAboutPageOfTheOnlineTrainingOnceOnTheSupportPagePleaseSelectTheButtonToSubmitYourRequestToNFPATheNFPACustomerSupportTeamWillGetBackToYouWithinBusinessHoursYouCanAlsoCallCustomerServiceDirectlyAt:
        "Please contact NFPA. You can select the Go to Support button on the About page of the online training. Once on the Support page, please select the button to submit your request to NFPA. The NFPA customer support team will get back to you within 24 business hours.",
    PleaseContactTheHostForTheEventTheHostContactInformationIsLocatedOnTheAboutPageOfTheEventUnderTheContactSection:
        "Please contact the host for the event. The host contact information is located on the About page of the event under the Contact section.",
    pleaseContactTheProviderOfTheEventToCancelOrReschedule:
        "Please contact the provider of the event to cancel or reschedule.",
    pleaseEnsureInstructorAvailabilityPriorToSettingUpATrainingTheLMSWillNotLetYouPublishAnEventUntilYouVerifyThatTheInstructorAvailabilityHasBeenConfirmed:
        "Please ensure instructor availability prior to setting up a training. The Learning Portal will not let you publish an event until you verify that the instructor availability has been confirmed.",
    pleaseReferenceYourContractWithTheInstructorAndWorkDirectlyWithThemToRectifyTheIssue:
        "Please reference your contract with the instructor and work directly with them to rectify the issue.",
    pleaseSelectTheButtonToSubmitYourQuestionToNFPATheNFPACustomerSupportTeamWillGetBackToYouWithinBusinessHours:
        "Please select the button to submit your question to NFPA. The NFPA customer support team will get back to you within 24 business hours.",
    pleaseSelectTheEventLinkOrCopyTheLinkToYourInternetBrowserTheEventLinkIsLocatedOnTheAboutPageOfTheEventUnderTheLocationSection:
        "Please select the event link or copy the link to your internet browser. The event link is located on the About page of the event under the Location section.",
    productMaterials: "Product Materials",
    representingYourSkillsWithABadgeGivesYouAMeansToShareYourAbilitiesOnlineInAWayThatIsSimpleTrustedAndCanBeEasilyVerifiedInRealTimeBadgesProvideEmployersAndPeersConcreteEvidenceOfWhatYouHadToDoToEarnYourCredentialAndWhatYoureNowCapableOfCredlyAlsoOffersLaborMarketInsightsBasedOnYourSkillsYouCanSearchAndApplyForJobOpportunitiesRightThroughCredly:
        "Representing your skills with a badge gives you a means to share your abilities online in a way that is simple, trusted, and can be easily verified in real time. Badges provide employers and peers concrete evidence of what you had to do to earn your credential and what you're now capable of. Credly also offers labor market insights, based on your skills. You can search and apply for job opportunities right through Credly.",
    selectTheEducationNetworkHubEitherFromTheQuickLinksOnYourDashboardOrFromTheHamburgerIconOnTheRighthandSideInTheHubYouWillFindALinkToTheNFPACatalogSpecificallyForAENMaterialsWhereYouCanPlaceYourOrder:
        "Select the Authorized Education Network Hub either from the quick links on your dashboard or from the hamburger icon on the right-hand side. In the hub you will find a link to the NFPA catalog specifically for AEN materials where you can place your order.",
    thereAreCurrentlyNoOnlineLearningContractsForThisOrganization:
        "There are Currently No Online Learning Contracts for This Organization.",
    thereWasAnIssueLoadingLearners: "There was an issue loading learners.",
    thereWasAnIssueUpdatingTheProductVersion: "There was an issue updating the product version.",
    thereWasAProblemReturningPastContractSummaries:
        "There was a problem returning past contract summaries.",
    thisNewVersionHasBeenPrepopulatedBasedOffOfThePreviousVersionToUseAsAStartingPoint:
        "This new version has been pre-populated based off of the previous version to use as a starting point.",
    thisProductWillNoLongerBeAvailableToUseForNewEventsIncludingAnyCurrentDraftEvents:
        "This Product will no longer be available to use for new Events, including any current draft Events.",
    viewAnnouncement: "View Announcement",
    viewVersion: "View Version",
    whatAreLaborMarketInsightsAndHowCanIAccessThemThroughCredly:
        "What are labor market insights and how can I access them through Credly?",
    whatAreTheBenefitsOfABadge: "What are the benefits of a badge?",
    whatIfCourseMaterialsDontArriveOnTimeForClass:
        "What if course materials don't arrive on time for class?",
    whatIfIDontWantMyBadgeToBePublic: "What if I don't want my badge to be public?",
    whatIfIHaveFeedbackAboutAnInstructorOrTheEnvironmentOfAnInstructorledEvent:
        "What if I have feedback about an instructor or the environment of an instructor-led event?",
    whatIfIHaveFeedbackAboutCourseContent: "What if I have feedback about course content?",
    whatIfIHaveQuestionsAboutAnInstructorledEventEgStartTimeLocation:
        "What if I have questions about an instructor-led event (e.g., start time, location)?",
    whatIfIHaveTroubleAccessingMyTrainingInTheLMS:
        "What if I have trouble accessing my training in the Learning Portal?",
    whatIfIHaveTroubleAccessingTheLMS: "What if I have trouble accessing the Learning Portal?",
    whatIfIHaveTroubleAccessingTheLMSOrTakingAttendance:
        "What if I have trouble accessing the Learning Portal or taking attendance?",
    whatIfIHaveTroubleSettingUpOrManagingEventsInTheLMS:
        "What if I have trouble setting up or managing events in the Learning Portal?",
    whatIfINeedToCancelrescheduleAnEventDate: "What if I need to cancel/reschedule an event date?",
    whatIfINeedToChangeOrCancelAClass: "What if I need to change or cancel a class?",
    whatIfINeedToChangeOrCancelAnEvent: "What if I need to change or cancel an event?",
    whatIfINeedToWithdrawFromAnInstructorledCourse:
        "What if I need to withdraw from an instructor-led course?",
    whatIfINeedToWithdrawFromAnOnlineLearningCourse:
        "What if I need to withdraw from an online learning course?",
    whatIfTheInstructorDoesntShowUpForClass: "What if the instructor doesn't show up for class?",
    whatIfThereAreNoInstructorsAvailableOnTheDateOfMyClass:
        "What if there are no instructors available on the date of my class?",
    whatIsAnOpenBadge: "What is an open badge?",
    whatsToKeepSomeoneElseFromCopyingMyBadgeAndUsingIt:
        "What's to keep someone else from copying my badge and using it?",
    whatWillHappenToMyNFPABadgeFromBADGR: "What will happen to my NFPA badge from BADGR?",
    whereAndHowCanIShareMyBadgeThroughCredly: "Where and how can I share my badge through Credly?",
    whereCanIFindMyInstructorRatingForAClass: "Where can I find my instructor rating for a class?",
    whereCanIFindThePrimaryContactOfTheTrainingProvider:
        "Where can I find the primary contact of the training provider?",
    whereYouCanProcessTheUpdatesThisWillAutomaticallyUpdateYourInformationInTheOnlineLearningPortalWithinHours:
        "where you can process the updates. This will automatically update your information in the Online Learning Portal within 24 hours.",
    whileBadgesAreSimplyDigitalImageFilesTheyAreUniquelyLinkedToDataHostedOnCredlyThisLinkToVerifiedDataMakesThemMoreReliableAndSecureThanAPaperbasedCertificateItAlsoEliminatesThePossibilityOfAnyoneClaimingYourCredentialAndYourAssociatedIdentity:
        "While badges are simply digital image files, they are uniquely linked to data hosted on Credly. This link to verified data makes them more reliable and secure than a paper-based certificate. It also eliminates the possibility of anyone claiming your credential and your associated identity.",
    whoDoIContactIfThereAreTechnologyIssuesDuringClass:
        "Whom do I contact if there are technology issues during class?",
    whoDoIContactWithFeedbackOnTheTrainingMaterials:
        "Whom do I contact with feedback on the training materials?",
    whatIsCredly: "What is Credly?",
    whoIsMyPrimaryContactAtNFPA: "Who is my primary contact at NFPA?",
    whyWasIOnlyGivenDigitalMaterialsForMyInstructorledClass:
        "Why was I only given digital materials for my instructor-led class?",
    yesYouCanDownloadYourBadgeFromTheShareBadgePageYourDownloadedBadgeContainsOpenBadgeInfrastructureOBICompliantMetadataEmbeddedIntoTheImageThisAllowsYouToStoreYourBadgeOnOtherOBIcompliantBadgeSitesSuchAsTheBadgrBackpack:
        "Yes, you can download your badge from the Share Badge page. Your downloaded badge contains Open Badge Infrastructure (OBI) compliant metadata embedded into the image. This allows you to store your badge on other OBI-compliant badge sites, such as the Badgr Backpack.",
    youAreActivatingProductVersionVersionId: "You Are Activating {{productVersionVersionId}}",
    youAreGoingToEditTheProductDetails: "You are going to edit the Product Details.",
    youAreViewingAnArchivedProduct: "You are viewing an Archived Product",
    youCanEditTheProductsContentAndAchievements:
        "You can edit the Product's Content and Achievements.",
    youCanFindTutorialsAndAnswersToAdditionalQuestionsHere:
        "You can find tutorials and answers to additional questions here:",
    youCannotSaveThisVersionUntilAllSectionsAreComplete:
        "You cannot save this version until all sections are complete.",
    youCanShareYourBadgeDirectlyFromCredlyToLinkedInTwitterAndFacebookOverEmailEmbeddedInAWebsiteOrInYourEmailSignature:
        "You can share your badge directly from Credly to LinkedIn, X (Twitter), and Facebook over email, embedded in a website, or in your email signature.",
    youCanUpdateYourInformationSuchAsTheSpellingOfYourNameOrYourEmailAddressByGoingToMyProfileWhichCanBeAccessedByUsingTheHamburgerIconWithinTheOnlineLearningPortalALinkWithinTheBasicInformationSectionWillDirectYouToYourNFPAAccountAt:
        "You can update your information by going to My Profile, which can be accessed by using the hamburger icon (☰) within the Online Learning Portal. A link within the Basic Information section will direct you to your NFPA account at",
    youHaveNoCurrentInstructorLedTrainings: "You Have No Current Instructor-Led Trainings",
    youHaveNoCurrentOnlineTrainings: "You Have No Current Online Trainings",
    youHaveNoPastTrainings: "You Have No Past Trainings",
    yourInstructorProfileCanBeViewedByNFPAStaffAsWellAsAENProvidersYourProfileIsUsedToAssistInTheSelectionOfInstructorAssignments:
        "Your instructor profile can be viewed by NFPA staff as well as AEN providers. Your profile is used to assist in the selection of instructor assignments.",
    yourOrganizationHasNoCurrentInstructorLedTrainings:
        "Your Organization Has No Current Instructor-Led Trainings",
    yourOrganizationHasNoCurrentOnlineTrainings:
        "Your Organization Has No Current Online Trainings",
    yourOrganizationHasNoPastInstructorLedTrainings:
        "Your Organization Has No Past Instructor-Led Trainings",
    youveCreatedVersionVersionNumber: "You've Created Version {{versionNumber}}",
    youWillHaveTheChoiceOnHowToApplyThisNewVersionToEventsThatAreCurrentlyInProgress:
        "You will have the choice on how to apply this new version to events that are currently in progress.",
    youWillHaveTheChoiceOnHowToApplyThisNewVersionToLearnersThatAreCurrentlyInProgress:
        "You will have the choice on how to apply this new version to Learners that are currently in progress.",

    fileFormatMustBePropsFileFormatAndLessThanPropsMaxFileSizeInMBMB:
        "File format must be {{propsFileFormat}} and less than {{propsMaxFileSizeInMB}}MB.",
    about: "About",
    aboutMe: "About Me",
    aboutThisCourse: "About This Course",
    aboutThisTraining: "About This Training",
    aboutThisUnit: "About This Unit",
    accessLocked: "Access Locked",
    account: "Account",
    accountFirstName: "Account First Name",
    accountLastName: "Account Last Name",
    achievements: "Achievements",
    aContractAlreadyExistsWithThisNumber: "A Contract Already Exists With This Number.",
    action: "Action",
    activateCourse: "Activate Course",
    activateProduct: "Activate Product",
    activatingTheProductFailedPleaseEnsureThereIsAProductNameDescriptionAndCoursesConfigured:
        "Activating the product failed. Please ensure there is a product name, description, and courses configured.",
    activationSummary: "Activation Summary",
    active: "Active",
    activity: "Activity",
    add: "Add",
    addAContract: "Add a Contract",
    addADay: "Add a Day",
    addAnEvent: "Add an Event",
    addAProduct: "Add a Product",
    addASession: "Add a Session",
    addAssessment: "Add Assessment",
    addAssessments: "Add Assessments",
    addAttempt: "Add Attempt",
    addBulkEnrollment: "Add Bulk Enrollment",
    addContent: "Add Content",
    addContract: "Add Contract",
    addContracts: "Add Contracts",
    addCourses: "Add Course(s)",
    addCoursesToStartBuildingOutTheUnit: "Add courses to start building out the unit.",
    addEnProvider: "Add AEN Provider",
    addEnrollment: "Add Enrollment",
    addEvents: "Add Events",
    addInstructor: "Add Instructor",
    additionalDetails: "Additional Details",
    additionalMaterialHasBeenAddedSpecificToTheContract:
        "Additional material has been added specific to the contract",
    additionalRequirements: "Additional Requirements",
    AdditionalResourcesMayBeFoundWithinEachCourse:
        "Additional resources may be found within each course.",
    addLeaner: "Add Leaner",
    addLearners: "Add Learners",
    addNew: "Add New",
    addNewAnnouncement: "Add New Announcement",
    addNewContract: "Add New Contract",
    addNewContractOrganization: "Add New Contract Organization",
    addNewCourse: "Add New Course",
    addNewENProvider: "Add New AEN Provider",
    addNewEvent: "Add New Event",
    addNewProduct: "Add New Product",
    addNewUser: "Add New User",
    addOrganization: "Add Organization",
    addOrganizations: "Add Organizations",
    addPackage: "Add Package",
    addProduct: "Add Product",
    addProducts: "Add Products",
    address: "Address",
    addressLine1: "Address Line 1",
    addressLine1IsRequired: "Address Line 1 is required",
    addressLine2: "Address Line 2",
    addToSchedule: "Add To Schedule",
    addUnit: "Add Unit",
    addUser: "Add User",
    administratorSlashInstructor: "Administrator/Instructor",
    aenAdministrator: "AEN Administrator",
    aenProvider: "AEN Provider",
    alabama: "Alabama",
    alaska: "Alaska",
    all: "All",
    allDay: "All Day",
    allRowsMustHaveCompleteData: "All rows must have complete data.",
    allTrainingsOllAndILT: "All Trainings (OLL and ILT)",
    allTrainingsReport: "All Trainings Report",
    allUsersReport: "All Users Report",
    alreadyEnrolled: "Already Enrolled",
    anAccountWithThisEmailAlreadyExists: "An account with this email already exists.",
    andDisplayedAs: "And displayed as...",
    anErrorOccurredCancelingThisEvent: "An error occurred canceling this Event.",
    anErrorOccurredPublishingThisEvent: "An error occurred publishing this Event.",
    anErrorOccurredSavingYourSelection: "An error occurred saving your selection.",
    anErrorOccurredUpdatingThisEvent: "An error occurred updating this Event.",
    anErrorOccurredWhileAddingTheAttempt: "An Error Occurred While Adding the Attempt.",
    anErrorOccurredWhileSavingTheAttempt: "An Error Occurred While saving the Attempt.",
    announcementBody: "Announcement Body",
    announcementName: "Announcement Name",
    announcements: "Announcements",
    announcementTitle: "Announcement Title",
    answer: "Answer",
    anyLearnersWhoHaveEnrolledInThePreviousVersionButHaveNotStartedWillReceiveThisNewVersion:
        "Any learners who have enrolled in the previous version but have not started will receive this new version.",
    approvedEventScheduleExceptionGetLastUpdatedText:
        "Approved {{eventScheduleExceptionGetLastUpdatedText}}",
    approvedInstructorsForThisProduct: "Approved Instructors for This Product",
    approvedToTeach: "Approved to Teach",
    arabic: "Arabic",
    archive: "Archive",
    archiveCourse: "Archive Course",
    archived: "Archived",
    archivedAnnouncements: "Archived Announcements",
    archiveProduct: "Archive Product",
    areYouSureYouWantToClearAllLanguageData: "Are you sure you want to clear all language data?",
    areYouSureYouWantToDeleteThisEvent: "Are you sure you want to delete this event?",
    areYouSureYouWantToExit: "Are you sure you want to exit?",
    areYouSureYouWantToRemoveCourseContentToDeleteContentName:
        "Are you sure you want to remove {{courseContentToDeleteContentName}}?",
    areYouSureYouWantToRemoveEventDayToDeleteGetEventDateTextFromYourSchedule:
        "Are you sure you want to remove {{eventDayToDeleteGetEventDateText}} from your schedule?",
    areYouSureYouWantToRemoveProductContentToDeleteContentName:
        "Are you sure you want to remove {{productContentToDeleteContentName}}?",
    areYouSureYouWantToRemoveProductScormPackageToDeleteScormPackageName:
        "Are you sure you want to remove {{productScormPackageToDeleteScormPackageName}}?",
    areYouSureYouWantToRemoveUnit: "Are you sure you want to remove {{unit}}?",
    areYouSureYouWantToRemoveUnitCourseToDeleteCourseName:
        "Are you sure you want to remove {{unitCourseToDeleteCourseName}}?",
    areYouSureYouWantToWithdrawThisLearner: "Are you sure you want to withdraw this learner?",
    areYouSureYouWouldLikeToCancelWithoutSavingYourChanges:
        "Are you sure you would like to cancel without saving your changes?",
    areYouSureYouWouldLikeToChangeTheAssociatedProduct:
        "Are you sure you would like to change the Associated Product? ",
    areYouSureYouWouldLikeToChangeTheEventSalesType:
        "Are you sure you would like to change the Event Sales Type?",
    areYouSureYouWouldLikeToChangeYourScheduleToAllDayEvents:
        "Are you sure you would like to change your schedule to All Day events?",
    areYouSureYouWouldLikeToLeaveWithoutSavingYourChanges:
        "Are you sure you would like to leave without saving your changes?",
    areYouSureYouWouldLikeToStartOver: "Are you sure you would like to start over?",
    arizona: "Arizona",
    arkansas: "Arkansas",
    arrowColumn: "Arrow Column",
    aShortDescriptionAboutYou: "A short description about yourself.",
    assessment: "Assessment",
    assessmentDeletedSuccessfully: "Assessment deleted successfully.",
    assessmentMarkedComplete: "Assessment Marked Complete.",
    assessmentName: "Assessment Name",
    assessmentResult: "Assessment Result",
    assessmentResults: "Assessment Results",
    assessments: "Assessments",
    assessmentScore: "Assessment Score",
    assignATraining: "Assign a Training",
    assignEventContactInformation: "Assign Event Contact Information",
    assignments: "Assignments",
    assignTraining: "Assign Training",
    associatedEnProvider: "Associated AEN Provider",
    associatedOrganization: "Associated Organization",
    associatedProduct: "Associated Product",
    associatedProductCannotBeEditedAfterContractIsCreated:
        "Associated product cannot be edited after contract is created.",
    associatedProductSelection: "Associated Product Selection",
    associatedUsers: "Associated Users",
    associateThisUserToAnExistingOrganization: "Associate this user to an existing organization.",
    asThePrimaryMethodToContactTheEventOrganizer:
        "as the primary method to contact the event organizer.",
    asYouAddDaysAndSessionsToYourEventScheduleTheyWillAppearHere:
        "As you add days and sessions to your event schedule, they will appear here.",
    attempt: "Attempt",
    attemptCreatedSuccessfully: "Attempt Created Successfully.",
    attemptCreationFailed: "Attempt Creation Failed.",
    attempted: "Attempted",
    attempts: "Attempts",
    attemptUpdatedSuccessfully: "Attempt Updated Successfully.",
    attendance: "Attendance",
    attendanceTrackedSuccessfully: "Attendance tracked successfully.",
    attendanceTrackingIsNotAvailableUntilTheDayOfTheEvent:
        "Attendance tracking is not available until the day of the event.",
    attendanceTrackingIsNotAvailableUntilTheStartOfTheEvent:
        "Attendance tracking is not available until the start of the event.",
    authenticateAs: "Authenticate As",
    availabilityConfirmed: "Availability Confirmed",
    availableForAen: "Available for AEN",
    backToAll: "Back to All",
    backToAllAssessments: "Back to All Assessments",
    backToAllAttendance: "Back to All Attendance",
    backToAllInstructorLedEventContracts: "Back to All Instructor-Led Event Contracts",
    backToAllOnlineLearningContracts: "Back to All Online Learning Contracts",
    backToAllTrainings: "Back to All Trainings",
    backToAllVersions: "Back to All Versions",
    backToAssessmentResults: "Back to Assessment Results",
    backToCourse: "Back to Course",
    backToEvaluationSummary: "Back to Evaluation Summary",
    backToParent: "Back to {{parent}}",
    backToSyllabus: "Back to Syllabus",
    badge: "Badge",
    badgeName: "Badge Name",
    badges: "Badges",
    basicInformation: "Basic Information",
    beforeChoosingYourInstructor: "before choosing your instructor.",
    bio: "Bio",
    browseCatalog: "Browse Catalog",
    by: "By",
    byDoingSoAnyInformationEnteredInTheEventsDetail:
        "By doing so, any information entered in the Event Details, Schedule and AEN Instructor sections will be reset.",
    byDoingSoAnyInformationEnteredInTheSchedule:
        "By doing so, any information entered in the Schedule and AEN Instructor sections will be reset.",
    byDoingSoEventTimesWillBeRemoved: "By doing so, event times will be removed.",
    byReplacingTheAssociatedENProviderAnyInformationEnteredForEventContactInformationWillBeReset:
        "By replacing the associated AEN provider, any information entered for Event Contact Information will be reset.",
    byReplacingTheAssociatedOrganizationAnyInformationEnteredForEventContactInformationWillBeReset:
        "By replacing the associated organization, any information entered for Event Contact Information will be reset.",
    byReplacingTheAssociatedProductAnyInformationEnteredInTheScheduleAndInstructorSectionsWillBeReset:
        "By replacing the associated product, any information entered in the Schedule and Instructor sections will be reset.",
    california: "California",
    cancel: "Cancel",
    canceled: "Canceled",
    cancelEdit: "Cancel Edit",
    cancelEvent: "Cancel Event",
    cancellationMessage: "Cancellation Message",
    cancelledonBYCancelledByNameCancelledById:
        "{{cancelledOn}} BY {{cancelledByName}} {{cancelledById}}",
    cancelVersion: "Cancel Version",
    ceCREDITS: "CE Credits",
    certificate: "Certificate",
    certificateExp: "Certificate Exp.",
    certificateExpiration: "Certificate Expiration",
    certificateNameWarning:
        "This is the name that will appear on certificates you earn in the Learning Portal. If for any reason you need to have a certificate reissued, please contact NFPA.",
    certificationPrep: "Certification Prep",
    ceus: "CEUs",
    ceusEnrollmentProductCreditHoursToString: "CEUs: {{enrollmentProductCreditHoursToString}}",
    ceusEventCEUs: "CEUs: {{eventCEUs}}",
    ceusEventProductCreditHoursToString: "CEUs: {{eventProductCreditHoursToString}}",
    ceusProductCreditHoursToString: "CEUs: {{productCreditHoursToString}}",
    ceusWillBeAvailableForDownloadOnceYouHaveCompletedAllRequiredElementsOfTheTraining:
        "CEUs will be available for download once you have completed all required elements of the training.",
    change: "Change",
    changedBy: "Changed By",
    changeHistory: "Change History",
    changeLog: "Change Log",
    changeOrder: "Change Order",
    changeStatus: "Change Status",
    changeStatusTo: "Change Status to",
    changesToZIPPackageAreNotAvailableWhenEditingTheCurrentCourseVersionToChangeTheZIPPackageCreateANewCourseVersion:
        "Changes to ZIP Package are not available when editing the current course version. To change the ZIP package, create a new course version.",
    changesWereMadeToThisSection: "Changes were made to this section.",
    changesWillBeAppliedToAllLearnersOnThisVersion:
        "Changes will be applied to all Learners on this version, regardless of completion status, and will not impact or reset a Learner's progress.",
    changesWillBeAppliedToAllLearnersRegardlessOfVersionOrCompletionStatusAndWillNotImpactOrResetALearnersProgress:
        "Changes will be applied to all Learners, regardless of version or completion status, and will not impact or reset a Learner's progress.",
    changesWillBeAppliedToAllLearnersRegardlessOfVersionOrCompletionStatusAndWillNotImpactOrRestALearnersProgress:
        "Changes will be applied to all Learners, regardless of version or completion status, and will not impact or rest a Learner's progress.",
    checkBackSoon: "Check back soon.",
    checklist: "Checklist",
    checkmarkChecked: "Checkmark Checked",
    checkmarkUnchecked: "Checkmark Unchecked",
    chooseDate: "Choose Date",
    chooseFile: "Choose File",
    cityIsRequired: "City is required",
    citySlashTown: "City/Town",
    clearAll: "Clear All",
    clearAllFilters: "Clear All Filters",
    clientAdmin: "Client Admin",
    clientAdminRoleCurrentlyHasAnActiveContractInTheLMS:
        "Client Admin role currently has an active contract in the Learning Portal.",
    close: "Close",
    colorado: "Colorado",
    community: "Community",
    complete: "Complete",
    completed: "Completed",
    completedTrainings: "Completed Trainings",
    completeUnitBeforeMovingForward: "Complete unit before moving forward.",
    completeUnitsInOrder: "Complete Units in Order",
    completion: "Completion",
    completionDate: "Completion Date",
    confirm: "Confirm",
    connecticut: "Connecticut",
    contact: "Contact",
    contactInfo: "Contact Info",
    contactNFPA: "Contact NFPA",
    content: "Content",
    contentAudience: "Content Audience",
    contentForAdministatorAndInstructor: "Content for Administator and Instructors",
    contentForParticipants: "Content for Participants",
    continueToEdit: "Continue to Edit",
    continueToVersion: "Continue to Version",
    contract: "Contract",
    contractContractContractNumber: "Contract #{{contractContractNumber}}",
    contractDate: "Contract Date",
    contractEvent: "Contract Event",
    contractIDProductIDAndFileAreNeeded: "Contract ID, Product ID and File are needed.",
    contractInformation: "Contract Information",
    contractNumber: "Contract Number",
    contractOrganization: "Contract Organization",
    contractOrganizationInformation: "Contract Organization Information",
    contractOrganizations: "Contract Organizations",
    contracts: "Contracts",
    correct: "Correct",
    country: "Country",
    countryIsRequired: "Country is required",
    course: "Course",
    courseAbbreviated: "C",
    courseDescription: "Course Description",
    courseDetails: "Course Details",
    courseEucUnitCourseSortOrder: "Course {{eucUnitCourseSortOrder}}",
    courseId: "Course ID",
    courseIdIsAutomaticallyGenerated: "Course ID is automatically generated and not editable.",
    courseIsNowActive: "Course is now active.",
    courseIsNowArchived: "Course is now archived.",
    courseIsNowDeactivated: "Course is now deactivated.",
    courseMaterials: "Course Materials",
    courseMustHaveANameAndType: "Course must have a name and type.",
    courseName: "Course Name",
    courseOrSeries: "Course or Series",
    courseProductReturnedAnUnexpectedResult: "Course Product returned an unexpected result.",
    courseResources: "Course Resources",
    courses: "Courses",
    courseTopicSelection: "Course Topic Selection",
    courseWasUndefined: "Course was undefined.",
    createAContract: "Create a Contract",
    createACourse: "Create a Course",
    createANewEvent: "Create a New Event",
    createANewProduct: "Create a New Product",
    createANewVersion: "Create a New Version",
    createCourse: "Create Course",
    createdondatetimeByThisCreatedByGetFirstAndLastName:
        "{{createdOnDateTime}} by {{thisCreatedByGetFirstAndLastName}}",
    createEvent: "Create Event",
    createNewVersion: "Create New Version",
    createProduct: "Create Product",
    createScormPackageFailed: "Create SCORM package failed.",
    createUnit: "Create Unit",
    createVersion: "Create Version",
    createYourFirstUnitToStartOrganizingCourses:
        "Create your first unit to start organizing courses.",
    creatingAnEnrollmentFailed: "Creating an enrollment failed.",
    creditHour: "Credit Hour",
    creditHours: "Credit Hours",
    currentAndUpcomingTrainings: "Current & Upcoming Trainings",
    currentAnnouncements: "Current Announcements",
    currentRequiredTrainings: "Current Required Trainings",
    currentStatus: "Current Status",
    currentTrainings: "Current Trainings",
    customizedContact: "Customized contact",
    customizeForThisEvent: "Customize For This Event",
    dashboard: "Dashboard",
    date: "Date",
    dateCreated: "Date Created",
    dateOfCompletion: "Date of Completion",
    dateRan: "Date Ran",
    dateRange: "Date Range",
    dateXXXXXXXX: "Date: XX/XX/XXXX",
    day: "Day",
    dayName: "Day Name",
    deactivateCourse: "Deactivate Course",
    deactivateProduct: "Deactivate Product",
    deactivateTheCourse: "Deactivate the course",
    deactivateTheProduct: "Deactivate the Product",
    deactivatingTheProductFailed: "Deactivating the product failed.",
    default: "Default",
    defaultContactInformation: "Default Contact Information",
    defaultEventContactInfo: "Default Event Contact Info",
    defaultEventContactInformation: "Default Event Contact Information",
    defaultEventInformation: "Default Event Information",
    defaultEventLocation: "Default Event Location",
    defaultEventTimezone: "Default Event Timezone",
    delaware: "Delaware",
    delete: "Delete",
    deleteAssessment: "Delete Assessment",
    deleteEvent: "Delete Event",
    deleteSession: "Delete Session",
    describeYourChanges: "Describe Your Changes",
    description: "Description",
    descriptionColumn: "Description Column",
    deselect: "Deselect",
    detailedBreakdown: "Detailed Breakdown",
    displayType: "Display Type",
    domestic: "Domestic",
    done: "Done",
    doubleDash: "--",
    downloadButtonForName: "Download button for {{name}}",
    downloadButtonForPropsContentItemName: "Download button for {{propsContentItemName}}",
    downloadCertificate: "Download Certificate",
    downloadCSVImportSummaryFile: "Download CSV Import Summary File",
    downloadLinkForCSVImportSummaryFile: "Download link for CSV Import Summary File",
    downloadResume: "Download Resume",
    downloadsForAdministratorAndInstructor: "Downloads for Administrator and Instructor",
    downloadsForParticipants: "Downloads for Participants",
    doYouWantToAssociateABadge: "Do you want to associate a badge with this Product?",
    draft: "Draft",
    duration: "Duration",
    duringThisTimePeriod: "During this time period..",
    edit: "Edit",
    editAnInstructorLedTrainingProduct: "Edit an Instructor-Led Training Product",
    editAnnouncement: "Edit Announcement",
    editAnOnlineLearningProduct: "Edit an Online Learning Product",
    editAttempt: "Edit Attempt",
    editCEUs: "Edit CEUs",
    editContract: "Edit Contract",
    editContractOrganization: "Edit Contract Organization",
    editCourse: "Edit Course",
    editCourseDetails: "Edit Course Details",
    editDay: "Edit Day",
    editEnProvider: "Edit AEN Provider",
    editEvent: "Edit Event",
    editingAnAttemptMayResultInAnUpdateToTheLearnersProgress:
        "Editing an Attempt may result in an update to the Learner's progress.",
    editingMode: "Editing Mode",
    editLearner: "Edit Learner",
    editName: "Edit Name",
    editProduct: "Edit Product",
    editUnit: "Edit Unit",
    editUser: "Edit User",
    editVersion: "Edit Version",
    educationNetwork: "Authorized Education Network",
    aenHub: "AEN Hub",
    educationNetworkEvent: "Authorized Education Network Event",
    educationNetworkHub: "Authorized Education Network Hub",
    educationNetworkResources: "Authorized Education Network Resources",
    electrical: "Electrical",
    email: "Email",
    emailAddress: "Email Address",
    emailIsInAValidFormatExampleEmaildomain: "Email is in a valid format (example: email@domain).",
    emergencyResponse: "Emergency Response",
    emptyDate: "00/00/0000",
    emptyString: "",
    emulateUser: "Emulate User",
    emulationNotAvailableForAdministrators: "Emulation not available for administrators.",
    emulationNotAvailableForAdmins: "Emulation not available for administrators.",
    endingEmulationSession: "Ending emulation session",
    endSession: "End Session",
    endTime: "End Time",
    english: "English",
    enInstructor: "AEN Instructor",
    enProductCatalog: "AEN Product Catalog",
    enProvider: "AEN Provider",
    enProviderEvent: "AEN Provider Event",
    enProviderInformation: "AEN Provider Information",
    enProviderName: "AEN Provider Name",
    enProviders: "AEN Providers",
    enProvidersOrgName: "AEN Provider's Org Name",
    enResource: "AEN Resource",
    instructorAssessment: "Instructor Assessment",
    enrolled: "Enrolled",
    enrolledAndCompleted: "Enrolled and Completed",
    enrolledButNotStartedEnrolledAndInProgress: "Enrolled But Not Started/Enrolled and In Progress",
    enrolledFirstName: "Enrolled First Name",
    enrolledLastName: "Enrolled Last Name",
    enrolledName: "Enrolled Name",
    enrollment: "Enrollment",
    enrollmentCSVRequirements: "Enrollment CSV Requirements:",
    enrollmentHistory: "Enrollment History",
    enrollmentImportStatus: "Enrollment Import Status:",
    enrollmentImportSummaryColon: "Enrollment Import Summary:",
    enrollmentLimit: "Enrollment Limit",
    enrollmentLimitCannotBeLessThanTheCurrentEnrollmentCount:
        "Enrollment limit cannot be less than the current enrollment count.",
    enrollmentReport: "Enrollment Report",
    enrollmentsForAEvent: "Enrollments for a Event",
    enrollmentsForProductName: "Enrollments for {{productName}}",
    enrollmentsForProductNameEventName: "Enrollments for {{productName}} - {{eventName}}",
    enrollmentStatusWillAppearAsInvitePending:
        'Enrollment status will appear as "Invite Pending" if the learner does not have a completed NFPA profile. Once added to the enrollment the learner will receive an email prompting them to finish setup of their NFPA profile. Once complete the learner\'s name will appear and replace "Invite Pending"."',
    enrollmentSuccessful: "Enrollment Successful.",
    enrollNewTraining: "Enroll in New Training",
    enrollTraining: "Enroll Training",
    enterAdditionalDetails: "Enter Additional Details",
    enterAdditionalRequirements: "Enter Additional Requirements",
    enterAddressLine1: "Enter Address Line 1",
    enterAddressLine2: "Enter Address Line 2",
    enterADescription: "Enter a Description",
    enterAnnouncementBody: "Enter Announcement Body",
    enterAnnouncementTitle: "Enter Announcement Title",
    enterChangeLog: "Enter Change Log",
    enterCitySlashTown: "Enter City/Town",
    enterContentDescription: "Enter Content Description",
    enterContentName: "Enter Content Name",
    enterContractNumber: "Enter Contract Number",
    enterCourseDescription: "Enter Course Description",
    enterCourseName: "Enter Course Name",
    enterCreditHours: "Enter Credit Hours",
    enterDayName: "Enter Day Name",
    enterDescribeYourChanges: "Enter Describe Your Changes",
    enterEmail: "Enter Email",
    enterEnrollmentLimit: "Enter Enrollment Limit",
    enterEventEmailAddress: "Enter Event Email Address",
    enterEventName: "Enter Event Name",
    enterEventPhoneNumber: "Enter Event Phone Number",
    enterFirstName: "Enter First Name",
    enterLastName: "Enter Last Name",
    enterLocationName: "Enter Location Name",
    enterMaximumRegistrationLimit: "Enter Maximum Registration Limit",
    enterMeetingUrl: "Enter Meeting URL",
    enterName: "Enter Name",
    enterNfpaContactEmail: "Enter NFPA Contact Email",
    enterNfpaContactName: "Enter NFPA Contact Name",
    enterNfpaContactPhone: "Enter NFPA Contact Phone",
    enterNfpaID: "Enter NFPA ID",
    enterOrganizationName: "Enter Organization Name",
    enterPackageName: "Enter Package Name",
    enterProductDescription: "Enter Product Description",
    enterProductName: "Enter Product Name",
    enterRegistrationURL: "Enter Registration URL",
    enterResponse: "Enter Response",
    enterUnitDescription: "Enter Unit Description",
    enterUnitName: "Enter Unit Name",
    enterYourEmailAddress: "Enter your email address...",
    enterYourFeedback: "Enter Your Feedback",
    enterYourFirstName: "Enter your first name...",
    enterYourLastName: "Enter your last name...",
    enterYourPhoneNumber: "Enter your phone number...",
    enterZipCodePostalCode: "Enter Zip Code/Postal Code",
    enterZipcodeSlashPostalCode: "Enter Zip Code/Postal Code",
    error: "Error",
    errorDeletingFile: "Error deleting file.",
    errorFetchingArchivedAnnouncements: "Error fetching archived announcements.",
    errorMessageColon: "Error Message:",
    errorWhenFetchingUnits: "Error when fetching units.",
    evaluation: "Evaluation",
    evaluationReport: "Evaluation Report",
    evaluationSubmittedSuccessfully: "Evaluation submitted successfully.",
    event: "Event",
    eventCalendar: "Event Calendar",
    eventCEUS: "Event CEUs",
    eventContact: "Event Contact",
    eventContactInformation: "Event Contact Information",
    eventdaygetcredithoursdescriptionEventDayGetCEUsCEUs:
        "{{eventDayGetCreditHoursDescription}} | {{eventDayGetCEUs}} CEUs",
    eventDayName: "Event Day Name",
    eventDaysCannotOccurOnTheSameDay: "Event Days cannot occur on the same day.",
    eventDeletedSuccessfully: "Event deleted successfully.",
    eventDetails: "Event Details",
    eventEnrollments: "Event Enrollments",
    eventID: "Event ID",
    eventInformation: "Event Information",
    eventName: "Event Name",
    eventPhoneNumber: "Event Phone Number",
    events: "Events",
    eventType: "Event Type",
    exceededEnrollmentLimit: "Exceeded Enrollment Limit",
    exceptionGranted: "Exception Granted",
    exceptionShouldOnlyBeGrantedBasedOnAValidExceptionReasonORAfterSpeakingWithTheRequestorToFullyUnderstandTheNeedForExtraHours:
        "Exception should only be granted based on a valid exception reason OR after speaking with the requestor to fully understand the need for extra hours. ",
    expiration: "Expiration",
    expirationDate: "Expiration Date",
    expirationExtendedSuccessfully: "Expiration extended successfully.",
    expirationExtensionFailed: "Expiration extension failed.",
    expired: "Expired",
    failed: "Failed",
    failedToActivateCourse: "Failed to activate course.",
    failedToActivateProduct: "Failed to activate Product.",
    failedToArchiveAnnouncement: "Failed to archive announcement.",
    failedToArchiveCourse: "Failed to archive course.",
    failedToArchiveProduct: "Failed to archive product.",
    failedToCreateANewEnrollment: "Failed to create a new enrollment.",
    failedToDeactivateCourse: "Failed to deactivate course.",
    failedToDeactivateProduct: "Failed to deactivate product.",
    failedToEnroll: "Failed to Enroll",
    failedToGetProductsCount: "Failed to get Products Count.",
    failedToLinkSCORMPackageToProduct: "Failed to link SCORM package to product.",
    failedToRetrieveProductScormPackages: "Failed to retrieve product scorm packages.",
    failedToSaveAttempt: "Failed to save attempt.",
    failedToUpdateUser: "Failed to update user",
    feedback: "Feedback",
    fieldsChanged: "Fields Changed",
    fileMayContainAHeaderRow: "File may contain a header row.",
    fileMustBeACSV: "File must be a CSV.",
    fileName: "File Name",
    fileNameColumn: "File Name Column",
    fileUpload: "File Upload",
    filterByEventType: "Filter by Event Type",
    filterByLanguage: "Filter by Language",
    filterByOrganization: "Filter by Organization",
    filterByProduct: "Filter by Product",
    filterByProvider: "Filter by Provider",
    filterByRole: "Filter by Role",
    filterBySalesType: "Filter by Sales Type",
    filterByStatus: "Filter by Status",
    filterByTeachingMethods: "Filter by Teaching Methods",
    filterByTopic: "Filter By Topic",
    filterByTravelPreferences: "Filter by Travel Preferences",
    filterResults: "Filter Results",
    firstName: "First Name",
    firstNameIsRequired: "First Name is required.",
    florida: "Florida",
    forAnyQuestionsPleaseContactNFPASupport: "For any questions please contact NFPA Support.",
    forQuestionsAboutTheEventContact: "For questions about the event, contact:",
    forQuestionsAboutTheLMSContact: "For questions about the Learning Portal, contact:",
    forQuestionsAboutTheTrainingContact: "For questions about the training, contact:",
    frequentlyAskedQuestions: "Frequently Asked Questions",
    fridayAbbreviated: "Fri",
    generalInformation: "General Information",
    generating: "Generating",
    georgia: "Georgia",
    getStarted: "Get Started",
    getStartedLearningPathButton: "Get Started Learning Path Button",
    getStartedSeriesButton: "Get Started Series Button",
    goToCredly: "Go to Credly",
    goToEvent: "Go to Event",
    goToEvents: "Go to Events",
    goToReports: "Go to Reports",
    goToSupport: "Go to Support",
    grantException: "Grant Exception",
    haveFeedback: "Have Feedback?",
    haveYouConfirmedThisInstructorsAvailability:
        "Have you confirmed this instructor's availability?",
    hawaii: "Hawaii",
    hideContactInformation: "Hide Contact Information",
    highScore: "High Score",
    host: "Host",
    hostTimeZone: "Host Time Zone",
    hours: "Hours",
    howItWorks: "How it works",
    howLongIsTheCertificateValidForInYears: "How long is the certificate valid for (in years)?",
    iAmAbleToTravelDomestically: "I am able to travel domestically",
    iAmAbleToTravelInternationally: "I am able to travel internationally",
    iAmAvailableToTeachInPerson: "I am available to teach in person",
    iAmAvailableToTeachVirtually: "I am available to teach virtually",
    iConfirmTheExceptionReasonIsValidAndorThatIHaveSpokenWithTheRequestorAndApproveThisException:
        "I confirm the exception reason is valid and/or that I have spoken with the requestor and approve this exception.",
    id: "ID",
    idaho: "Idaho",
    ids: "IDs",
    ifALearnerRequiresAdditionalTimeToCompleteTheTrainingTheExpirationDateMayBeExtended:
        "If a learner requires additional time to complete the training, the expiration date may be extended.",
    ifYouBelieveThisIsAnErrorPleaseContact: "If you believe this is an error, please contact",
    iHaveConfirmedThisInstructorsAvailability: "I have confirmed this instructor's availability *",
    illinois: "Illinois",
    importCSV: "Import CSV",
    importEnrollmentCSV: "Import Enrollment CSV",
    importStatus: "Import Status",
    inactive: "Inactive",
    includeArchived: "Include Archived",
    includedInThisUnit: "Included in this unit",
    incomplete: "Incomplete",
    incorrect: "Incorrect",
    indiana: "Indiana",
    inHyphenProgress: "In-Progress",
    inOrderToArchiveThisCourseYouNeedTo: "In order to Archive this course you need to:",
    inOrderToArchiveThisProductYouNeedTo: "In order to Archive this product you need to:",
    inOrderToDeactivateThisCourseYouNeedTo: "In order to Deactivate this course you need to:",
    inPerson: "In-Person",
    inPersonEvent: "In-Person Event",
    inPersonTraining: "In-Person Training",
    inProgress: "In Progress",
    inprogressLearners: "In-Progress Learners",
    inProgressLearners: "In-Progress Learners",
    inputTheEventClassNameTheClassNameWillNotBeSeen:
        "Input the event class name. The class name will not be seen by learners and is used only for internal organizational purposes.",
    instructor: "Instructor",
    instructorAverageRating: "Instructor Average Rating",
    instructorInfo: "Instructor Info",
    instructorInformation: "Instructor Information",
    instructorLed: "Instructor-Led",
    instructorLedEventsContracts: "Instructor-Led Events Contracts",
    instructorLedTraining: "Instructor-Led Training",
    instructorLedTrainingEvent: "Instructor-Led Training Event",
    instructorName: "Instructor Name",
    instructorProfile: "Instructor Profile",
    instructorRoleCannotBeRemovedDueToIncompleteEventAssignments:
        "Instructor role cannot be removed due to incomplete event assignment(s).",
    international: "International",
    inThisTimePeriod: "In this time period...",
    inverted: "Inverted",
    invitePending: "Invite Pending",
    iowa: "Iowa",
    issueChangingPreferredLanguage: "There was an issue changing your preferred language.",
    iWantToRunATrainingStatisticsReportFor: "I want to run a Training Statistics report for...",
    iWantToRunAn: "I want to run a(n)...",
    iWantToRunAnAllTrainingReportBasedOn: "I want to run an All Training report based on...",
    iWantToRunAnEvaluationReportBasedOn: "I want to run an Evaluation report based on...",
    iWantToRunAReport: "I want to run a report on...",
    iWantToRunATrainingMaterialsOutlineBasedOn:
        "I want to run a Training Materials Outline based on...",
    iWantToRunATranscriptReportFor: "I want to run a Transcript report for...",
    kansas: "Kansas",
    kentucky: "Kentucky",
    language: "Language",
    languageBarrierIsAnExampleWhereTrainingMayExtend:
        "Language barrier is an example where training may extend beyond the recommended contact hours (CEU's), (i.e., the language of the training and materials is not the primary language of the participants).",
    languageBarriers: "Language barriers",
    languageSelect: "Select the language you'd like to use with the Learning Portal.",
    languageSelection: "Language Selection",
    languageUpdatedSuccessfully: "Language updated successfully.",
    lastChange: "Last Change",
    lastChanged: "Last Changed",
    lastName: "Last Name",
    lastNameIsRequired: "Last Name is required.",
    launchAssessment: "Launch Assessment",
    launchCourse: "Launch Course",
    launchTraining: "Launch Training",
    learner: "Learner",
    learnerAssessment: "Learner Assessment",
    learnerBreakdown: "Learner Breakdown",
    learnerName: "Learner Name",
    learnerRegisteredSuccessfully: "Learner registered successfully.",
    learnersInThisGroupMatchedRecordsForLearnersAlreadyOnTheEnrollmentListForTheTraining:
        "Learners in this group matched records for learners already on the enrollment list for the training.",
    learnersInThisGroupWereNotAddedToTheEnrollmentListDueToExceedingTheRegistrationLimitForThisTraining:
        "Learners in this group were not added to the enrollment list due to exceeding the registration limit for this training.",
    learnersInThisGroupWereNotAddedToTheEnrollmentListForThisTrainingDueToAPotentialDataError:
        "Learners in this group were not added to the enrollment list for this training due to a potential data error.",
    learnersInThisGroupWereSuccessfullyAddedToTheEnrollmentListForTheTraining:
        "Learners in this group were successfully added to the enrollment list for the training.",
    learnersWhoHaveCompletedThePreviousVersionWillRemainOnThatVersion:
        "Learners who have completed the previous version will remain on that version.",
    learnerTranscriptReport: "Learner Transcript Report",
    learning: "Learning",
    learningPath: "Learning Path",
    letUsKnowHowWeCanHelp: "Let us know how we can help.",
    licensee: "Licensee",
    linkingCourseToAUnitHasFailed: "Linking course to a unit has failed.",
    linkingSCORMPackageToProductFailed: "Linking SCORM package to product failed",
    listAndLast: "{{list}}, and {{last}}",
    live: "Live",
    liveVirtual: "Live Virtual",
    liveVirtualEvent: "Live Virtual Event",
    liveVirtualTraining: "Live Virtual Training",
    lmsID: "Learning Portal ID",
    lmsId: "Learning Portal ID",
    loadingEmulationSession: "Loading emulation session",
    location: "Location",
    locationName: "Location Name",
    locationNameIsRequired: "Location Name is required",
    logIn: "Log In",
    logOut: "Log Out",
    louisiana: "Louisiana",
    mainContent: "Main Content",
    maine: "Maine",
    manageYourProfile: "Manage your profile on www.nfpa.org",
    markComplete: "Mark Complete",
    markingNotificationAsReadFailed: "Marking Notification as read failed.",
    markPassed: "Mark Passed",
    maryland: "Maryland",
    massachusetts: "Massachusetts",
    maximumRegistration: "Maximum Registration",
    maximumRegistrationLimit: "Maximum Registration Limit",
    maxScore: "Max Score",
    meetingUrl: "Meeting URL",
    memberOf: "Member of",
    menu: "Menu",
    menuColumn: "Menu Column",
    messageByUpdatedByGetFirstAndLastName: "{{message}} by {{updatedByGetFirstAndLastName}}",
    michigan: "Michigan",
    minnesota: "Minnesota",
    mississippi: "Mississippi",
    missouri: "Missouri",
    mondayAbbreviated: "Mon",
    montana: "Montana",
    moveAssessmentProductAssessmentScormPackageNameDown:
        "Move assessment {{productAssessmentScormPackageName}} down",
    moveAssessmentProductAssessmentScormPackageNameUp:
        "Move assessment {{productAssessmentScormPackageName}} up",
    moveContentCourseContentContentNameDown: "Move content {{courseContentContentName}} down",
    moveContentCourseContentContentNameUp: "Move content {{courseContentContentName}} up",
    moveContentPcContentNameDown: "Move content {{pcContentName}} down",
    moveContentPcContentNameUp: "Move content {{pcContentName}} up",
    moveContentProductContentContentNameDown: "Move content {{productContentContentName}} down",
    moveContentProductContentContentNameUp: "Move content {{productContentContentName}} up",
    moveCourseUnitCourseCourseNameDown: "Move course {{unitCourseCourseName}} down",
    moveCourseUnitCourseCourseNameUp: "Move course {{unitCourseCourseName}} up",
    moveUnitProductUnitNameDown: "Move unit {{productUnitName}} down",
    moveUnitProductUnitNameUp: "Move unit {{productUnitName}} up",
    myLearning: "My Learning",
    myProfile: "My Profile",
    myTeachingAssignments: "My Teaching Assignments",
    name: "Name",
    nameColumn: "Name Column",
    nationalFireProtectionAssociation: "National Fire Protection Association",
    nationalFireProtectionAssociationCatalog: "National Fire Protection Association Catalog",
    nebraska: "Nebraska",
    needFurtherAssistance: "Need Further Assistance?",
    needToAddOrRemoveTeamMembers: "Need to Add or Remove Team Members?",
    neutral: "Neutral",
    nevada: "Nevada",
    new: "New",
    newAnnouncement: "New Announcement",
    newContractOrganization: "New Contract Organization",
    newCourse: "New Course",
    newENProvider: "New AEN Provider",
    newEnrollments: "New Enrollments",
    newExpirationDate: "New Expiration Date",
    newHampshire: "New Hampshire",
    newJersey: "New Jersey",
    newMexico: "New Mexico",
    newOnlineLearningContract: "New Online Learning Contract",
    newUser: "New User",
    newVersionVersionNumber: "New Version {{versionNumber}}",
    newYork: "New York",
    nextMonth: "Next Month",
    nextPage: "Next Page",
    nfpa: "NFPA",
    nfpaAdmin: "NFPA Admin",
    nfpaAdminEmail: "NFPA Admin Email",
    nfpaAdminName: "NFPA Admin Name",
    nfpaAdminPhone: "NFPA Admin Phone",
    nfpaCatalog: "NFPA Catalog",
    nfpaCatalogEvent: "NFPA Catalog Event",
    nfpaContact: "NFPA Contact",
    nfpaContactEmail: "NFPA Contact Email",
    nfpaContactName: "NFPA Contact Name",
    nfpaContactPhone: "NFPA Contact Phone",
    nfpaCustomerSupport: "NFPA Customer Support",
    nfpaEvent: "NFPA Event",
    nfpaId: "NFPA ID",
    nfpaIsCommittedToProvidingYouWithHighqualityContentToHelpMeetYourLearningNeedsWeAppreciateYourFeedbackYourAnswersAndCommentsWillProvideValuableInputAsWeContinueToEnhanceOurOfferings:
        "NFPA is committed to providing you with high-quality content to help meet your learning needs. We appreciate your feedback. Your answers and comments will provide valuable input as we continue to enhance our offerings.",
    nfpaOperations: "NFPA Operations",
    nfpaSupport: "NFPA Support",
    no: "No",
    noAssignmentsForThisTraining: "No assignments for this training.",
    noAttemptDataForTheSelectedAttempt: "No attempt data for the selected attempt.",
    noChangesWereMadeToThisSection: "No changes were made to this section",
    noCoursesAdded: "No courses added.",
    noEnrollmentLimit: "NO ENROLLMENT LIMIT",
    noEvaluationAdded: "No Evaluation Added",
    noHostAssigned: "No Host Assigned",
    noInstructorAssigned: "No Instructor Assigned",
    noInstructorsCouldBeFoundForThisProduct: "No Instructors Could Be Found for This Product",
    noKeepEditing: "No, Keep Editing",
    noLimitSetInvoiceWillBeIssuedFollowingTheEvent:
        "No limit set. Invoice will be issued following the event.",
    noLocationAdded: "No Location Added",
    noOrganizationSelected: "No Organization Selected",
    noOrganizationsFound: "No Organizations Found.",
    noProductAssigned: "No Product Assigned",
    noProductSelected: "No product selected",
    noProviderSelected: "No Provider Selected",
    noProvidersFound: "No Providers Found.",
    noResultsFoundPleaseTryAgain: "No results found. Please try again.",
    northCarolina: "North Carolina",
    northDakota: "North Dakota",
    noScheduleCreated: "No Schedule Created",
    notApplicableShort: "N/A",
    notAValidCultureCode: "Not a valid culture code.",
    noteAnInvitePendingLearnerCountsTowardsTheTotalEnrollmentNumberForTheTraining:
        'Note: An "Invite Pending" learner counts towards the total enrollment number for the training."',
    noteBreakTimeIsNOTToBeIncludedInAdjustedHoursCEUs:
        "Note: Break time is NOT to be included in adjusted hours/CEUs.",
    noteTheExpirationHistoryLogWillTrackEachTimeTheExpirationDateIsUpdated:
        "Note: The expiration history log will track each time the expiration date is updated.",
    noteYouDoNotNeedToIncludeTheEventType:
        "Note: You do not need to include the event type (live-virtual or in person) in the class name as this will be noted in the event type selection.",
    notFound: "Not Found",
    noTrainingsFound: "No trainings found.",
    notStarted: "Not Started",
    noUnitsAdded: "No units added.",
    noZipPackageAdded: "No zip package added.",
    number: "Number",
    ohio: "Ohio",
    oklahoma: "Oklahoma",
    oneVeryUnlikelyOnAScaleOfOneToTen: "One Very Unlikely on a scale of one to ten",
    online: "Online",
    onlineLearning: "Online Learning",
    onlineLearningContracts: "Online Learning Contracts",
    onlineLearningProductEnrollments: "Online Learning Product Enrollments",
    onlineLearningTraining: "Online Learning Training",
    onlyAvailableForNFPAOLLTrainingProducts: "Only Available for NFPA OLL Training Products",
    optional: "Optional",
    order: "Order",
    oregon: "Oregon",
    organization: "Organization",
    organizationDefaultEventContact: "Organization Default Event Contact",
    organizationName: "Organization Name",
    organizations: "Organizations",
    organizationsNeedToBeAddedBeforeTheyCanBeAssignedToAUser:
        "Organizations need to be added before they can be assigned to a user.",
    organizationsNeedToBeAddedBeforeTheyCanBeAssociatedToAUser:
        "Organizations need to be added before they can be associated to a user.",
    organizationStatus: "Organization Status",
    organizedBy: "Organized by",
    original: "Original",
    OrSimplyMakeSureYourFileMeetsTheseRequirementsBeforeUploading:
        " or simply make sure your file meets these requirements before uploading:",
    orSimplyMakeSureYourFileMeetsTheseRequirementsBeforeUploading:
        "or simply make sure your file meets these requirements before uploading:",
    other: "Other",
    overview: "Overview",
    paginationNavigationNext: "Pagination Navigation Next",
    paginationNavigationPrevious: "Pagination Navigation Previous",
    participants: "Participants",
    participantsRequireAdditionalLearningTimeBasedOnInitialKnowledgeLevel:
        "Participants require additional learning time based on initial knowledge level.",
    participantSummary: "Participant Summary",
    passed: "Passed",
    password: "Password",
    pastAnnouncementsWillDisplayHereOnceTheyHaveBeenArchived:
        "Past announcements will display here once they have been archived.",
    pastTrainings: "Past Trainings",
    payloadCannotBeNullOrUndefined: "Payload cannot be null or undefined",
    pending: "Pending",
    pennsylvania: "Pennsylvania",
    phone: "Phone",
    phoneNumber: "Phone Number",
    physicalMaterials: "Physical Materials",
    please: "Please",

    pleaseContactTheHostForStartAndEndTimes: "Please contact the host for start and end times.",
    pleaseEnterAValidEmailAddress: "Please enter a valid email address.",
    pleaseEnterAValidPhoneNumber: "Please Enter a Valid Phone Number",
    pleaseReviewBeforeReplacingYourENProvider: "Please review before replacing your AEN Provider.",
    pleaseReviewBeforeReplacingYourOrganization:
        "Please review before replacing your organization.",
    pleaseReviewBeforeReplacingYourProduct: "Please review before replacing your product.",
    pleaseReviewTheSummaryEvaluationResultsFromParticipantsAsItProvidesKeyInsightsAboutTheirTrainingExperienceResultsIncludeFeedbackConcerningTheInstructorTrainingContentAndTheLearningEnvironment:
        "Please review the summary evaluation results from participants as it provides key insights about their training experience. Results include feedback concerning the instructor, training content, and the learning environment.",
    pleaseSelectAllThatApply: "Please select all that apply.",
    pleaseSelectTheDateAndTimesOfTheEventScheduleDONOTIncludeBreaksIncludingLunchInTheStartAndEndTimesAsTheseTimesAreConsideredNoncontactHoursAndAreNotToBeCountedTowardsContinuingEducationCreditsCEUsForExampleAHourOnedayCourseCouldBeSetUpAs:
        "Please select the date and times of the event schedule. DO NOT include breaks (including lunch) in the start and end times as these times are considered non-contact hours and are not to be counted towards continuing education credits (CEUs). For example, a 7-hour one-day course could be set up as:",
    preferredLanguage: "Preferred Language",
    preview: "Preview",
    previewAssessment: "Preview Assessment",
    previewEvaluation: "Preview Evaluation",
    previousMonth: "Previous Month",
    previousPage: "Previous Page",
    problemLoadingAssociatedProducts: "There was a problem loading the associated products list.",
    problemLoadingAssociatedProductsList:
        "There was a problem loading the associated products list.",
    problemLoadingOrganizations: "There was a problem loading the organizations list.",
    problemLoadingProducts: "There was an issue loading products.",
    problemLoadingProviders: "There was a problem loading the providers list.",
    processing: "Processing",
    product: "Product",
    productAudience: "Product Audience",
    productDescription: "Product Description",
    productDetails: "Product Details",
    productID: "Product ID",
    productId: "Product ID",
    productIDAndFileAreNeeded: "Product ID and File are needed.",
    productIdIsAutomaticallyGeneratedAndNotEditable:
        "Product ID is automatically generated and not editable.",
    productInfo: "Product Info",
    productInformation: "Product Information",
    productIsNowActive: "Product is now active.",
    productIsNowArchived: "Product is now archived.",
    productIsNowDeactivated: "Product is now deactivated.",
    productName: "Product Name",
    products: "Products",
    productType: "Product Type",
    productTypeSelection: "Product Type Selection",
    profile: "Profile",
    profileUpdateFailed: "Profile Update Failed.",
    profileUpdateFailedAddingLanguage: "Profile Update Failed Adding Language.",
    profileUpdateFailedAddingTeachingMethod: "Profile Update Failed Adding Teaching Method.",
    profileUpdateFailedAddingTravelMethod: "Profile Update Failed Adding Travel Method.",
    profileUpdateFailedRemovingLanguage: "Profile Update Failed Removing Language.",
    profileUpdateFailedRemovingTeachingMethod: "Profile Update Failed Removing Teaching Method.",
    profileUpdateFailedRemovingTravelMethod: "Profile Update Failed Removing Travel Method.",
    progress: "Progress",
    propstextNotSelected: "{{propsText}} not selected",
    propstextSelected: "{{propsText}} selected",
    provider: "Provider",
    providerName: "Provider Name",
    publish: "Publish",
    publishCourse: "Publish Course",
    published: "Published",
    publishEvent: "Publish Event",
    publishingSummary: "Publishing Summary",
    question: "Question",
    questionBreakdown: "Question Breakdown",
    quickLinks: "Quick Links",
    register: "Register",
    registration: "Registration",
    registrationInformation: "Registration Information",
    registrationInformationDoesNotApplyForContractEventsToViewMoreInformationRegardingTheContractVisitTheEventDetailsTab:
        "Registration information does not apply for Contract Events. To view more information regarding the contract, visit the Event Details tab.",
    registrationLink: "Registration Link",
    registrationURL: "Registration URL",
    remove: "Remove",
    removeCourseFromAnyProducts: "Remove Course from any Products",
    removeInstructor: "Remove Instructor",
    replace: "Replace",
    replaceAnENProvider: "Replace an AEN Provider",
    replaceAnOrganization: "Replace an Organization",
    replaceContent: "Replace Content",
    replaceENProvider: "Replace AEN Provider",
    replaceOrganization: "Replace Organization",
    replacePackage: "Replace Package",
    replaceProduct: "Replace Product",
    reportDownloads: "Report Downloads",
    reportNameReport: "{{reportName}}",
    reports: "Reports",
    reportType: "Report Type",
    required: "Required",
    requiredTrainings: "Required Trainings",
    reset: "Reset",
    resetALL: "RESET ALL",
    resetForAllLanguages: "Reset for All Languages",
    resources: "Resources",
    response: "Response",
    result: "Result",
    resumeTraining: "Resume Training",
    returnToHomepage: "Return to Homepage",
    reviewAssessmentResults: "Review Assessment Results",
    reviewEvaluationResults: "Review Evaluation Results",
    reviewTraining: "Review Training",
    reviewTrainingContent: "Review Training Content",
    rhodeIsland: "Rhode Island",
    role: "Role",
    roles: "Roles",
    runAReport: "Run a Report",
    running: "Running",
    runningLMSAppVersionProcessEnvREACTAPPBUILDVERSION:
        "Running Learning Portal App Version: {{processEnvREACTAPPBUILDVERSION}}",
    runReport: "Run Report",
    salesType: "Sales Type",
    saturdayAbbreviated: "Sat",
    save: "Save",
    saveAndExit: "Save & Exit",
    saveChanges: "Save Changes",
    saveContent: "Save Content",
    saveCourseDetailsChanges: "Save Course Details Changes",
    saveExpirationDate: "Save Expiration Date",
    savePackage: "Save Package",
    savingAnAttemptMayResultInAnUpdateToTheLearnersProgress:
        "Saving an Attempt may result in an update to the Learner's progress.",
    schedule: "Schedule",
    scheduleSummary: "Schedule Summary",
    score: "Score",
    search: "Search",
    searchByBadgeName: "Search by badge name",
    searchByEmailNameOrId: "Search by email, name, or ID",
    searchByEventNameOrId: "Search by event name or ID",
    searchByName: "Search by name",
    searchByNameOrId: "Search by name or ID",
    searchByNameOrLocation: "Search by name or location",
    searchByOrganizationName: "Search by Organization Name",
    searchByProviderName: "Search by Provider Name",
    searchOrFilterToSeeResults: "Search or Filter to see results",
    select: "Select",
    selectABadgeToAssociateWithThisProduct: "Select a badge to associate with this product.",
    selectADate: "Select a Date",
    selectADateRange: "Select a Date Range",
    selectADayThatContainsAnInstructorLedTrainingToViewMoreInformation:
        "Select a day that contains an instructor-led training to view more information.",
    selectAFile: "Select a File",
    selectALanguage: "Select a Language",
    selectAllRolesThatWillApplyToThisUser: "Select all roles that will apply to this user.",
    selectAnAssociatedProduct: "Select an Associated Product",
    selectAnENProvider: "Select an AEN Provider",
    selectAnIssue: "Select an Issue",
    selectAnOption: "Select an Option",
    selectAnOrganization: "Select an Organization",
    selectAProduct: "Select a Product",
    selectATime: "Select a Time",
    selectATopic: "Select a Topic",
    selectContract: "Select Contract",
    selectContracts: "Select Contracts",
    selectCourses: "Select Courses",
    selected: "Selected",
    selectedInstructorWillAppearHere: "Selected instructor will appear here.",
    selectedProductWillAppearHere: "Selected Product Will Appear Here",
    selectENProvider: "Select AEN Provider",
    selectEnProvider: "Select AEN Provider",
    selectEvents: "Select Events",
    selectFile: "Select File",
    selectForAnInPersonEvent: "Select for an In-Person Event",
    selectForAVirtualEvent: "Select for a virtual event (e.g., using Microsoft Teams or Zoom)",
    selectInstructor: "Select Instructor",
    selectLearners: "Select Learners",
    selectOption: "Select Option",
    selectOrganization: "Select Organization",
    selectOrganizations: "Select Organizations",
    selectProduct: "Select Product",
    selectProducts: "Select Products",
    selectTimeZone: "Select Time Zone",
    selectToViewAnAttempt: "Select to view an attempt",
    series: "Series",
    session: "Session",
    session1: "Session 1",
    sessionEndTimeMayNotBePriorToStartTime: "Session end time may not be prior to start time.",
    sessionExpiresAt: "Session expires at:",
    sessionIdx: "Session {{idx}}",
    sessionPropsSessionNumberToString: "Session {{propsSessionNumberToString}}",
    sessionTimesMayNotOverlap: "Session Times may not overlap.",
    setEmailError: "Please enter a valid email address.",
    shareMyLocationInformation: "Share my location information",
    shortDateFormat: "MM/DD/YYYY",
    shouldThisCertificateHaveAnExpirationDate: "Should this certificate have an expiration date?",
    sidebar: "Sidebar",
    skipToMainContent: "Skip to Main Content",
    someLocation: "Some Location",
    sortOrderColumn: "Sort Order Column",
    southCarolina: "South Carolina",
    southDakota: "South Dakota",
    spaceAtSpace: " at ",
    spanish: "Spanish",
    startDate: "Start Date",
    startOver: "Start Over",
    startTimeAmEndTimeAm: "Start Time: 8:00 a.m. - End Time: 11:30 a.m.",
    startTimePmEndTimePm: "Start Time: 1:00 p.m. - End Time: 4:30 p.m.",
    stateIsRequired: "State is required",
    stateSlashProvinceSlashRegion: "State/Province/Region",
    status: "Status",
    submit: "Submit",
    submitAQuestion: "Submit a Question",
    submitEvaluation: "Submit Evaluation",
    submitFeedback: "Submit Feedback",
    submitted: "Submitted",
    success: "Success",
    successfullyEnrolled: "Successfully Enrolled",
    summary: "Summary",
    sundayAbbreviated: "Sun",
    support: "Support",
    switchProfile: "Switch Profile",
    syllabus: "Syllabus",
    systemInformation: "System Information",
    takeAMomentToReviewYourChangesAndPopulateTheChangeLogBeforeSaving:
        "Take a moment to review your changes and populate the change log before saving.",
    takeAMomentToReviewYourChangesBeforeSavingOnceYouSaveAllEventParticipantsWillBeNotified:
        "Take a moment to review your changes before saving. Once you save, all event participants will be notified.",
    takeAMomentToReviewYourCourseBeforeActivatingIfYouWouldLikeToMakeAnyChangesReturnToTheAppropriatePageOfTheJourney:
        "Take a moment to review your Course before activating. If you would like to make any changes, return to the appropriate page of the journey.",
    takeAMomentToReviewYourEventBeforePublishingIfYouWouldLikeToMakeAnyChangesReturnToTheAppropriatePageOfTheJourney:
        "Take a moment to review your Event before publishing. If you would like to make any changes, return to the appropriate page of the journey.",
    takeAMomentToReviewYourProductBeforeActivatingIfYouWouldLikeToMakeAnyChangesReturnToTheAppropriatePageOfTheJourney:
        "Take a moment to review your Product before activating. If you would like to make any changes, return to the appropriate page of the journey.",
    takeAttendanceDateUtilsFormatDatetruecontentItemEventDateToString:
        "Take Attendance {{DateUtilsFormatDatetruecontentItemEventDateToString}}",
    takeEvent: "Take Event",
    tcredithoursTcreditHourCEU: "{{tcreditHours}} (1 {{tcreditHour}} = 0.1 CEU)",
    teachingCalendar: "Teaching Calendar",
    teachingContactInformation: "Teaching Contact Information",
    teachingHistory: "Teaching History",
    teachingLanguages: "Teaching Languages",
    teachingMethods: "Teaching Methods",
    teachingPreferences: "Teaching Preferences",
    template: "Template",
    templateSelection: "Template Selection",
    tennessee: "Tennessee",
    tenVeryLikelyOnAScaleOfOneToTen: "Ten Very Likely on a scale of one to ten",
    texas: "Texas",
    thatIsDisplayedAsA: "That is displayed as a...",
    theContentFromThePreviousVersionHasBeenBroughtOverToUseAsAStartingPoint:
        "The content from the previous version has been brought over to use as a starting point.",
    theEvaluationWasNotSubmittedForThisTraining:
        "The evaluation was not submitted for this Training.",
    theEvaluationWillBecomeAvailableFollowingTheCompletionOfTheSyllabus:
        "The evaluation will become available following the completion of the Syllabus.",
    theLastDayOfTheTraining: "the last day of the training",
    thePointOfContactForThisOrganizationInCaseLearnersHaveQuestions:
        "The point of contact for this organization in case learners have questions regarding event logistics.",
    thePreviewIsNotAvailableYet: "The preview is not available yet.",
    theRecommendedClassTimeForThisEventIsProductCreditHoursHoursAndShouldNotExceedMaxCreditHoursHours:
        "The recommended class time for this event is {{productCreditHours}} hours and should not exceed {{maxCreditHours}} hours.",
    thereWasAnErrorDeletingEmulationSessionToken:
        "There was an error deleting emulation session token",
    thereWasAnErrorEndingEmulationSession: "There was an error ending the emulation session.",
    thereWasAnErrorUploadingTheScormPackagePleaseTryAgain:
        "There was an error uploading the scorm package.  Please try again.",
    thereWasAnIssueAddingTheCourse: "There was an issue adding the course.",
    thereWasAnIssueAddingTheOrganization: "There was an issue adding the organization.",
    thereWasAnIssueAuthenticating: "There was an issue authenticating.",
    thereWasAnIssueCalculatingThePercentCompletePleaseRefreshThePageToViewThePercentComplete:
        "There was an issue calculating the percent complete. Please refresh the page to view the percent complete. ",
    thereWasAnIssueChangingYourPreferredLanguage:
        "There was an issue changing your preferred language.",
    thereWasAnIssueCheckingForDuplicateContractNumbers:
        "There was an issue checking for duplicate contract numbers.",
    thereWasAnIssueCreatingACourse: "There was an issue creating a course.",
    thereWasAnIssueCreatingAnEvent: "There was an issue creating an event.",
    thereWasAnIssueCreatingAProduct: "There was an issue creating a product.",
    thereWasAnIssueCreatingCourseContent: "There was an issue creating course content.",
    thereWasAnIssueCreatingTheContract: "There was an issue creating the contract.",
    thereWasAnIssueCreatingTheContractInformation:
        "There was an issue creating the contract information.",
    thereWasAnIssueCreatingTheUnit: "There was an issue creating the unit.",
    thereWasAnIssueCreatingTheUser: "There was an issue creating the user.",
    thereWasAnIssueCreatingTheUserRole: "There was an issue creating the user role.",
    thereWasAnIssueDeletingACourse: "There was an issue deleting a course.",
    thereWasAnIssueDeletingAUnit: "There was an issue deleting a unit.",
    thereWasAnIssueDeletingTheAssessment: "There was an issue deleting the assessment.",
    thereWasAnIssueDeletingTheCourseContent: "There was an issue deleting the course content.",
    thereWasAnIssueDeletingTheEvent: "There was an issue deleting the event.",
    thereWasAnIssueDeletingTheEventDay: "There was an issue deleting the event day.",
    thereWasAnIssueDeletingTheEventSession: "There was an issue deleting the event session.",
    thereWasAnIssueDeletingTheProductContent: "There was an issue deleting the product content.",
    thereWasAnIssueDeletingTheUnit: "There was an issue deleting the unit.",
    thereWasAnIssueDeletingTheUnitCourse: "There was an issue deleting the unit course.",
    thereWasAnIssueDeletingTheUserRole: "There was an issue deleting the user role.",
    thereWasAnIssueFetchingCourseProductData: "There was an issue fetching course product data.",
    thereWasAnIssueGeneratingAPreviewLink: "There was an issue generating a preview link.",
    thereWasAnIssueLaunchingThisCourse: "There was an issue launching this course.",
    thereWasAnIssueLoadingAssessmentResults: "There was an issue loading assessment results.",
    thereWasAnIssueLoadingAssessments: "There was an issue loading assessments.",
    thereWasAnIssueLoadingAttempts: "There was an issue loading attempts.",
    thereWasAnIssueLoadingAttendance: "There was an issue loading attendance.",
    thereWasAnIssueLoadingContracts: "There was an issue loading contracts.",
    thereWasAnIssueLoadingCourseContents: "There was an issue loading course contents.",
    thereWasAnIssueLoadingCourseDetails: "There was an issue loading course details.",
    thereWasAnIssueLoadingCourses: "There was an issue loading courses.",
    thereWasAnIssueLoadingEnrollments: "There was an issue loading enrollments.",
    thereWasAnIssueLoadingEvaluationDetails: "There was an issue loading evaluation details.",
    thereWasAnIssueLoadingEventContent: "There was an issue loading event content.",
    thereWasAnIssueLoadingEvents: "There was an issue loading events.",
    thereWasAnIssueLoadingInstructors: "There was an issue loading instructors.",
    thereWasAnIssueLoadingOrganizations: "There was an issue loading organizations.",
    thereWasAnIssueLoadingProducts: "There was an issue loading products.",
    thereWasAnIssueLoadingProviders: "There was an issue loading providers.",
    thereWasAnIssueLoadingQuestionBreakdowns: "There was an issue loading question breakdowns.",
    thereWasAnIssueLoadingTheAssessment: "There was an issue loading the assessment.",
    thereWasAnIssueLoadingTheBadge: "There was an issue loading the badge.",
    thereWasAnIssueLoadingTheBadgeTemplateDetails:
        "There was an issue loading the badge template details.",
    thereWasAnIssueLoadingTheBadgeTemplates: "There was an issue loading the badge templates.",
    thereWasAnIssueLoadingTheCertificate: "There was an issue loading the certificate.",
    thereWasAnIssueLoadingTheCourse: "There was an issue loading the course.",
    thereWasAnIssueLoadingTheCourseDetails: "There was an issue loading the course details.",
    thereWasAnIssueLoadingTheCurrentAnnouncement:
        "There was an issue loading the current announcement.",
    thereWasAnIssueLoadingTheEnrollment: "There was an issue loading the enrollment.",
    thereWasAnIssueLoadingTheEvaluation: "There was an issue loading the evaluation.",
    thereWasAnIssueLoadingTheEvaluationTemplates:
        "There was an issue loading the evaluation templates.",
    thereWasAnIssueLoadingTheEvent: "There was an issue loading the event.",
    thereWasAnIssueLoadingTheLearnerChecklist: "There was an issue loading the learner checklist.",
    thereWasAnIssueLoadingTheProduct: "There was an issue loading the product.",
    thereWasAnIssueLoadingTheProductDetails: "There was an issue loading the product details.",
    thereWasAnIssueLoadingTheRegistration: "There was an issue loading the registration.",
    thereWasAnIssueLoadingTheReportDownloads: "There was an issue loading the report downloads.",
    thereWasAnIssueLoadingTheScheduleAttendance:
        "There was an issue loading the schedule attendance.",
    thereWasAnIssueLoadingTheSeededUsers: "There was an issue loading the seeded users.",
    thereWasAnIssueLoadingTheUserRoles: "There was an issue loading the user roles.",
    thereWasAnIssueLoadingTheUsers: "There was an issue loading the users.",
    thereWasAnIssueLoadingUnits: "There was an issue loading units.",
    thereWasAnIssueLoadingUser: "There was an issue loading user.",
    thereWasAnIssueLoadingUserInfo: "There was an issue loading user info.",
    thereWasAnIssueLoadingUserRoles: "There was an issue loading user roles.",
    thereWasAnIssueMarkingThisAssessmentComplete:
        "There was an issue marking this assessment complete.",
    thereWasAnIssueProductIDWasUndefined: "There was an issue. Product ID was undefined",
    thereWasAnIssuePublishingTheAnnouncement: "There was an issue publishing the announcement.",
    thereWasAnIssueRegisteringTheLearner: "There was an issue registering the learner.",
    thereWasAnIssueReplacingTheZipPackage: "There was an issue replacing the zip package.",
    thereWasAnIssueSavingTheProductSelection: "There was an issue saving the product selection.",
    thereWasAnIssueSettingTheThisCourseHasNoContentToggle:
        "There was an issue setting the 'This Course Has No Content' toggle.",
    thereWasAnIssueSettingTheThisProductHasNoContentToggle:
        "There was an issue setting the 'This Product Has No Content' toggle.",
    thereWasAnIssueSubmittingTheEvaluation: "There was an issue submitting the evaluation.",
    thereWasAnIssueSwitchingProfiles: "There was an issue switching profiles.",
    thereWasAnIssueTrackingAttendance: "There was an issue tracking attendance.",
    thereWasAnIssueUpdatingEnrollments: "There was an issue updating enrollments.",
    thereWasAnIssueUpdatingSortOrderAfterDeletingUnit:
        "There was an issue updating sort order after deleting unit.",
    thereWasAnIssueUpdatingTheAssessment: "There was an issue updating the assessment.",
    thereWasAnIssueUpdatingTheBadgeTemplate: "There was an issue updating the badge template.",
    thereWasAnIssueUpdatingTheCertifiateExpiration:
        "There was an issue updating the certifiate expiration.",
    thereWasAnIssueUpdatingTheCEUs: "There was an issue updating the CEUs.",
    thereWasAnIssueUpdatingTheContent: "There was an issue updating the content.",
    thereWasAnIssueUpdatingTheContract: "There was an issue updating the contract.",
    thereWasAnIssueUpdatingTheCourse: "There was an issue updating the course.",
    thereWasAnIssueUpdatingTheCourseName: "There was an issue updating the course name.",
    thereWasAnIssueUpdatingTheEvaluationTemplate:
        "There was an issue updating the evaluation template.",
    thereWasAnIssueUpdatingTheEventDay: "There was an issue updating the event day.",
    thereWasAnIssueUpdatingTheEventsInstructor:
        "There was an issue updating the Event's instructor.",
    thereWasAnIssueUpdatingTheFieldName: "There was an issue updating the {{fieldName}}.",
    thereWasAnIssueUpdatingTheOrganization: "There was an issue updating the organization.",
    thereWasAnIssueUpdatingTheProduct: "There was an issue updating the product.",
    thereWasAnIssueUpdatingTheProductName: "There was an issue updating the product name.",
    thereWasAnIssueUpdatingTheProductWhileStartingOver:
        "There was an issue updating the product while starting over.",
    thereWasAnIssueUpdatingTheScheduleException:
        "There was an issue updating the schedule exception.",
    thereWasAnIssueUpdatingTheSortOrder: "There was an issue updating the sort order.",
    thereWasAnIssueUpdatingTheUnit: "There was an issue updating the unit.",
    thereWasAnIssueUpdatingTheUnitReceivedAnUnexpectedResult:
        "There was an issue updating the unit. Received an unexpected result.",
    thereWasAnIssueWithdrawingAnEnrollment: "There was an issue withdrawing an enrollment.",
    thereWasAProblemCreatingANewEnrollment: "There was a problem creating a new enrollment.",
    thereWasAProblemCreatingTheReportDownload: "There was a problem creating the report download.",
    thereWasAProblemGettingAvailableReportDownloads:
        "There was a problem getting available report downloads.",
    thereWasAProblemGettingAvailableReports: "There was a problem getting available reports.",
    thereWasAProblemGettingProductAssessments: "There was a problem getting product assessments.",
    thereWasAProblemGettingRegistration: "There was a problem getting registration.",
    thereWasAProblemLoadingEnrollments: "There was a problem loading enrollments.",
    thereWasAProblemLoadingTheAssociatedProductsList:
        "There was a problem loading the associated products list.",
    thereWasAProblemLoadingTheContract: "There was a problem loading the contract.",
    thereWasAProblemLoadingTheEvent: "There was a problem loading the event.",
    thereWasAProblemLoadingTheOrganization: "There was a problem loading the organization.",
    thereWasAProblemLoadingTheProfile: "There was a problem loading the profile.",
    thereWasAProblemLoadingTheReportDownloads: "There was a problem loading report downloads.",
    thereWasAProblemRemovingACourseFromTheUnitWhileStartingOver:
        "There was a problem removing a course from the unit while starting over.",
    thereWasAProblemReturningBadgeTemplates: "There was a problem returning Badge Templates.",
    thereWasAProblemReturningContracts: "There was a problem returning contracts.",
    thereWasAProblemReturningContractSummaries: "There was a problem returning contract summaries.",
    thereWasAProblemReturningCourses: "There was a problem returning courses.",
    thereWasAProblemReturningEnrollmentCount: "There was a problem returning enrollment count.",
    thereWasAProblemReturningEnrollments: "There was a problem returning enrollments.",
    thereWasAProblemReturningEventChangeLogs: "There was a problem returning event change logs.",
    thereWasAProblemReturningEvents: "There was a problem returning events.",
    thereWasAProblemReturningNotifications: "There was a problem returning notifications.",
    thereWasAProblemReturningOrganizations: "There was a problem returning organizations.",
    thereWasAProblemReturningProducts: "There was a problem returning products.",
    thereWasAProblemReturningProductTrainingContent:
        "There was a problem returning product training content.",
    thereWasAProblemReturningProductUnits: "There was a problem returning product units.",
    thereWasAProblemReturningRoles: "There was a problem returning roles.",
    thereWasAProblemReturningTheChecklist: "There was a problem returning the checklist.",
    thereWasAProblemSavingTheZipPackage: "There was a problem saving the Zip Package.",
    thereWasAProblemUpdatingTheAnnouncement: "There was a problem updating the announcement.",
    thereWasAProblemUpdatingTheChecklist: "There was a problem updating the checklist.",
    thereWasAProblemUpdatingTheContent: "There was a problem updating the content.",
    thereWasAProblemUpdatingTheContract: "There was a problem updating the contract.",
    thereWasAProblemWithCreatingProductContent:
        "There was a problem with creating product content.",
    thereWasAProblemWithManagingContent: "There was a problem with managing content.",
    thereWasAProblemWithProductContentManager: "There was a problem with product content manager.",
    thereWasProblemSavingBulkEnrollments: "There was a problem saving bulk enrollments.",
    thereWillBeNoImpactToTheseLearnersAndTheyWillRetainAccessToTheVersionTheyCompletedUntilItExpires:
        "There will be no impact to these Learners and they will retain access to the version they completed until it expires.",
    theseEventsAreForContractsOnly: "These events are for contracts only.",
    theseEventsAreOpenToAllAndRunByNFPA:
        "These events are open to all (general public) and run by NFPA.",
    theseLearnersWillBeAbleToFinishThisProductAndRetainAccessToTheCurrentVersionAssignedToThemUntilItExpires:
        "These Learners will be able to finish this Product and retain access to the current version assigned to them until it expires.",
    theSelectedFileTypeIsNotPermitted: "The selected file type is not permitted.",
    thisAttemptWasManuallyEnteredByNFPA: "This Attempt was manually entered by NFPA.",
    thisContactInformationWillBeAvailableToEventParticipantsAs:
        "This contact information will be available to event participants as",
    thisContactInformationWillBeAvailableToEventParticipantsAsThePrimaryMethodToContactTheEventOrganizer:
        "This contact information will be available to event participants as the primary method to contact the event organizer.",
    thisCourseHasNoContent: "This course has no content",
    thisDataWillStayInSyncWithAnyChangesMadeToYourOrganizationsDefaultContactInfo:
        "This data will stay in sync with any changes made to your Organization's Default Contact Info.",
    thisEventHasNoRegistrationLimit: "THIS EVENT HAS NO REGISTRATION LIMITS",
    thisEVENTHASNOREGISTRATIONLIMITS: "THIS EVENT HAS NO REGISTRATION LIMITS",
    thisEventIsA: "This event is a:",
    thisEventIsCurrentlyLive: "This Event is Currently Live",
    thisEventWillBeCanceledAndParticipantsWillBeNotifiedThisActionCannotBeUndoneYoullBeAbleToAccessTheEventPageButWontBeAbleToEditTheEvent:
        "This event will be canceled and participants will be notified. This action cannot be undone. You'll be able to access the event page but won't be able to edit the event.",
    thisFileIncludesAHeaderRow: "This file includes a header row ",
    thisInformationIsSpecificToThisEventAndWillNotChangeOrSyncWithTheOrganizationsDefaultEventContactInformation:
        "This information is specific to this event and will not change or sync with the Organization's default event contact information.",
    thisInstructorCurrentlyHasNoApprovedToTeachTrainings:
        "This Instructor Currently Has No Approved to Teach Trainings",
    thisInstructorCurrentlyHasNoAssignedRequiredTrainings:
        "This Instructor Currently Has No Assigned Required Trainings",
    thisInstructorCurrentlyHasNoCompletedTrainings:
        "This Instructor Currently Has No Completed Trainings",
    thisInstructorCurrentlyHasNoPastTrainings: "This instructor currently has no past trainings.",
    thisNameIsUsedOnlyInTrainingManagement: "This name is used only in Training Management.",
    thisProductAlreadyHasMultipleUnitsLearningPath:
        "This product already has multiple Units. Learning Path!",
    thisRegistrationEventWillBePublishedAdvertisedOnTheNFPACalendarWebsiteAndWillBeVisibleToTheGeneralPublic:
        "This registration event will be published (advertised) on the NFPA calendar website and will be visible to the general public.",
    thisRegistrationEventWillNotBeIncludedOnTheNFPACalendarWebsite:
        "This registration event will not be included on the NFPA calendar website.",
    thisTrainingExpiredWithIncompleteItems:
        "This training expired with incomplete items. Review your Training Summary for more detail.",
    thisTrainingHasBeenCanceled: "This training has been canceled.",
    thisUnitIsOptional: "This unit is optional.",
    thisWillImmediatelyRemoveThemFromThisTraining:
        "This will immediately remove them from this training.",
    thursdayAbbreviated: "Thu",
    time: "Time",
    timezone: "Timezone",
    toEnrollInAllOtherTrainingsNavigateToTheIndividualContractOrILTEventsEnrollmentSection:
        "To enroll in all other trainings, navigate to the individual Contract or ILT Event's enrollment section.",
    topic: "Topic",
    topicColon: "Topic:",
    topics: "Topics",
    topicSelection: "Topic Selection",
    total: "Total",
    totalCEUs: "Total CEUs:",
    totalClassTime: "Total Class Time",
    totalEnrollmentCountText: "Total: {{enrollmentCountText}}",
    totalEnrollmentsTotalLearnersParticipated: "Total Enrollments / Total Learners Participated",
    totalImported: "Total Imported",
    totalNumberOfCeusAwarded: "Total Number of CEUs Awarded",
    totalNumberOfLearnersAwardedCeus: "Total Number of Learners Awarded CEUs",
    totalTrainingsConducted: "Total Trainings Conducted",
    totalUsedEnrollmentCount: "Total: {{usedEnrollmentCount}}",
    trainingAccessExp: "Training Access Exp.",
    trainingAccessExpiration: "Training Access Expiration",
    trainingAccessExpirationDate: "Training Access Expiration Date",
    trainingAccessExpirationHistory: "Training Access Expiration History",
    trainingAchievements: "Training Achievements",
    trainingManager: "Training Manager",
    trainingMaterialsOutlineReport: "Training Materials Outline Report",
    trainingName: "Training Name",
    trainingOverview: "Training Overview",
    trainingRequired: "Training Required",
    trainings: "Trainings",
    trainingStatistics: "Training Statistics",
    trainingSummary: "Training Summary",
    trainingSummaryILT: "Training Summary (ILT)",
    trainingType: "Training Type",
    trainTheTrainer: "Train the Trainer",
    travelPreferences: "Travel Preferences",
    tuesdayAbbreviated: "Tue",
    twelcomeLearnerFirstName: "{{twelcome}}, {{learnerFirstName}}",
    twelcomeUserFirstName: "{{twelcome}}, {{userFirstName}}",
    type: "Type",
    typeSearchByCourseNameOrId: "Type to search by course name or ID",
    typeToSearchByContractNameOrID: "Type to search by contract name or ID",
    typeToSearchByContractNumber: "Type to search by contract number",
    typeToSearchByCourseNameOrID: "Type to search by course name or ID",
    typeToSearchByEventNameOrID: "Type to search by event name or ID",
    typeToSearchByName: "Type to search by name",
    typeToSearchByNameOrId: "Type to search by name or ID",
    typeToSearchByOppNumber: "Type to search by OPP number",
    typeToSearchByProductNameOrID: "Type to search by product name or ID",
    typeToSearchByProductNameOrId: "Type to search by product name or ID",
    typeToSearchByTrainingName: "Type to search by training name",
    typeToSearchProducts: "Type to search products",
    unableToCreateEmulationToken: "Unable to create emulation token.",
    unableToLoginAsSubscriber: "Unable to login as subscriber",
    unassigned: "Unassigned",
    unassignTraining: "Unassign Training",
    unauthorized: "Unauthorized",
    unit: "Unit",
    unitAbbreviated: "U",
    unitDescription: "Unit Description",
    unitDetails: "Unit Details",
    unitEnrollmentUnitSortOrder: "Unit {{enrollmentUnitSortOrder}}",
    unitName: "Unit Name",
    unitSettings: "Unit Settings",
    unitUnitSortOrder: "UNIT {{unitSortOrder}}",
    unitUpdatedSuccessfully: "Unit updated successfully.",
    unknown: "Unknown",
    upcomingEvents: "Upcoming Events",
    updateContent: "Update Content",
    updatedbygetfirstandlastnameMessage: "{{updatedByGetFirstAndLastName}} | {{message}}",
    updateExpiration: "Update Expiration",
    updateExpirationDate: "Update Expiration Date",
    updateYourContactInformationOnNfpaOrg: "Update your contact information on nfpa.org",
    updateYourLocationOnNfpaOrg: "Update your location on nfpa.org",
    upload: "Upload",
    uploadACSVFile: "Upload a CSV File:",
    uploadAProfilePicture: "Upload a Profile Picture",
    uploadResumeOrCV: "Upload Resume or CV",
    useDefault: "Use Default",
    userHasBeenAddedSuccessfully: "User has been added successfully.",
    userHasBeenUpdatedSuccessfully: "User has been updated successfully.",
    userManagement: "User Management",
    userPreferredLanguage: "User Preferred Language",
    userRoles: "User Roles",
    users: "Users",
    useThisTemplate: "use this template",
    utah: "Utah",
    vermont: "Vermont",
    version: "Version",
    versionVersionNumber: "Version {{versionNumber}}",
    veryLIKELY: "VERY LIKELY",
    veryUNLIKELY: "VERY UNLIKELY",
    viewAchievements: "View Achievements",
    viewAENProvider: "View AEN Provider",
    viewAll: "View All",
    viewAssessmentDetail: "View Assessment Detail",
    viewCatalog: "View Catalog",
    viewContract: "View Contract",
    viewCourse: "View Course",
    viewENProvider: "View AEN Provider",
    viewEvent: "View Event",
    viewOrganization: "View Organization",
    viewProvider: "View Provider",
    viewResults: "View Results",
    viewSummary: "View Summary",
    viewTheChangeHistory: "View the Change History",
    viewTheVersionHistory: "View the Version History",
    viewTraining: "View Training",
    viewUnit: "View Unit",
    viewUserInfo: "View User Info",
    viewYourProfile: "View Your Profile",
    virginia: "Virginia",
    wantToTeachMoreTopics: "Want to Teach More Topics?",
    warning: "Warning",
    washington: "Washington",
    wednesdayAbbreviated: "Wed",
    welcome: "Welcome",
    WeNoticedThisIsAContractInPersonEvent:
        "We noticed this is a Contract, In-Person Event. Use this toggle to set to all day when session times are unknown.",
    westVirginia: "West Virginia",
    wisconsin: "Wisconsin",
    withdraw: "Withdraw",
    withdrawALearner: "Withdraw a Learner",
    withdrawEnrollmentSuccessful: "Withdraw Enrollment Successful.",
    withdrawLearner: "Withdraw Learner",
    withdrawn: "Withdrawn",
    wouldYouLikeThisEventPublishedInTheNFPACalendar:
        "Would you like this event published in the NFPA Calendar?",
    wyoming: "Wyoming",
    yes: "Yes",
    yesCancel: "Yes, Cancel",
    yesChange: "Yes, Change",
    yesIWantToCancelThisEventAndUnderstandThisActionCannotBeUndone:
        "Yes, I want to cancel this event and understand this action cannot be undone. ",
    yesLeave: "Yes, Leave",
    yesRemove: "Yes, Remove",
    youAreDeactivatingTheProductPleaseReviewCarefullyBeforeDeactivating:
        "You are deactivating the product. Please review carefully before deactivating.",
    youAreEditingTheCourseDetails: "You Are Editing the Course Details.",
    youAreEmulating: "You are emulating:",
    youAreGoingToCreateVersionVersionNumber: "You are going to Create Version {{versionNumber}}",
    youAreGoingToEditTheCourseDetails: "You are going to edit the Course Details.",
    youAreGoingToEditVersionVersionNumber: "You are going to Edit Version {{versionNumber}}",
    youAreLoggedInWithYourIdentityRoleNameRole:
        "You are logged in with your {{identityRoleName}} role.",
    youAreNowEditingALiveEvent: "You Are Now Editing a Live Event",
    youAreViewingADraftEvent: "You are viewing a Draft Event.",
    youAreViewingAnArchivedCourse: "You are viewing an archived course.",
    youAreWithdrawingEnrollmentName: "You are withdrawing {{enrollmentName}}.",
    youCanAddAShortCancellationMessageThisMessageWillBeCommunicatedToParticipantsAndDisplayedInTheLMS:
        "You can add a short cancellation message. This message will be communicated to participants and displayed in the Learning Portal.",
    youCanEditTheCoursesContent: "You can edit the Course's Content.",
    youDoNotHaveAccessToThisPageBasedOnYourNFPALMSRoleTypeDisplayNamescurrentRoleProfile:
        "You do not have access to this page based on your NFPA Learning Portal {{RoleTypeDisplayNamescurrentRole}} Profile",
    youHaveBeenAddedOnBehalfOfContractOrganizationNameYourDataMayBeSharedWithThisOrganization:
        "You have been added on behalf of {{contractOrganizationName}}. Your data may be shared with this organization.",
    youHaveBeenAddedOnBehalfOfEventOrganizationNameYourDataMayBeSharedWithThisOrganization:
        "You have been added on behalf of {{eventOrganizationName}}. Your data may be shared with this organization.",
    youHaveExceededTheRecommendedAmountOfClassTime:
        "You have exceeded the recommended amount of class time.",
    youHaveNoCurrentAnnouncements: "You have no current announcements.",
    youHaveUnsavedChanges: "You have unsaved changes.",
    youMustChangesToZIPPackageAreNotAvailableWhenEditingTheCurrentCourseVersionToChangeTheZIPPackageYouMustCreateANewCourseVersion:
        "You must Changes to ZIP Package are not available when editing the current course version. To change the ZIP package, you must create a new course version.",
    youMustContentChangesWillBeAppliedToAllLearnersOnThisVersionRegardlessOfCompletionStatusAndWillNotImpactOrResetALearnersProgress:
        "You must Content changes will be applied to all Learners on this version, regardless of completion status, and will not impact or reset a Learner's progress.",
    youMustUploadACSVFile: "You must upload a CSV file.",
    youMustUploadAJPGSmallerThan3MB: "You must upload a JPG smaller than 3MB.",
    youMustUploadAFileTypeSmallerThanXMB:
        "You must upload a {{FileTypes}} smaller than {{MaxFileSize}}MB.",
    youMustUploadANewZIPPackage: "You must upload a new ZIP Package.",
    youMustUploadAPDFSmallerThan5MB: "You must upload a PDF smaller than 5MB.",
    youMustUploadAZIPFile: "You must upload a ZIP file.",
    youMustUploadAZipFile: "You must upload a zip file.",
    yourCertificateOfCompletionWith: "Your certificate of completion with",
    yourOrganization: "Your Organization",
    yourProfileAndInformationWillBeSharedWithLearnersAsAPointOfContact:
        "Your profile and information will be shared with learners as a point of contact.",
    yourReportIsRunningYoullReceiveANotificationWhenYourReportIsReady:
        "Your report is running. You'll receive a notification when your report is ready.",
    yourResponses: "Your Responses",
    yourRole: "Your Role",
    youveBeenGrantedAnExceptionToExceedMaxCreditHoursHoursForYourEvent:
        "You've been granted an exception to exceed {{maxCreditHours}} hours for your event.",
    youveEnteredCEUs: "You've entered 0.0 CEUs.",
    youveEnteredCeusCEUs: "You've entered {{ceus}} CEUs.",
    youWereWithdrawnFromThisTrainingOn: "You were withdrawn from this training on {{date}}.",
    youWillHaveTheChoiceOnHowToApplyThisNewVersionToLearnersThatAreCurrentlyInProgressChoosingToApplyThisVersionWillResultInResettingTheirProgress:
        "You will have the choice on how to apply this new version to learners that are currently in progress. Choosing to apply this version will result in resetting their progress.",
    zipCodeIsRequired: "Zip Code is required",
    zipCodeSlashPostalCode: "Zip Code/Postal Code",
    zipPackage: "Zip Package",
    activateVersion: "Activate Version",
    activatingCourseFailed: "Activating course failed.",
    activatingProductFailed: "Activating product failed.",
    addAnInstructor: "Add an Instructor",
    addAnOrganization: "Add an Organization",
    addInstructors: "Add Instructors",
    advanceYourCareerWithTraining: "Advance your career with training direct from the source.",
    AnErrorOccurredWhileDownloadingTheContractCertificates:
        "An error occurred while downloading the certificates for the contract.",
    AnErrorOccurredWhileDownloadingTheEventCertificates:
        "An error occurred while downloading the certificates for the event.",
    announcementMustHaveAnEnglishTitleAndEnglishBody:
        "Announcement must have an English title and English body.",
    applyToLearners: "Apply to Learners",
    archivingAnnouncementFailed: "Archiving announcement failed.",
    archivingCourseFailed: "Archiving course failed.",
    archivingProductFailed: "Archiving product failed.",
    AreYouSureYouWouldLikeToCancelWithoutSavingYourChanges:
        "Are you sure you would like to cancel without saving your changes?",
    assessmentsWillBeAvailableToCompleteOnLastEventDate:
        "Assessment(s) will be available to complete on {{lastEventDate}}.",
    attended: "Attended",
    byCreatingThisNewVersionLearnersWhoHaveCompletedThePreviousVersionWillRemainOnThatVersionAnyLearnersWhoHaveEnrolledInThePreviousVersionButHaveNoStartedWillReceiveThisNewVersion:
        "By creating this new version, learners who have completed the previous version will remain on that version. Any learners who have enrolled in the previous version but have no started will receive this new version.",
    byCreatingThisNewVersionLearnersWhoHaveCompletedThePreviousVersionWillRemainOnThatVersionAnyLearnersWhoHaveEnrolledInThePreviousVersionButHaveNotStartedWillReceiveThisNewVersionHowWouldYouLikeToApplyThisNewVersionToLearnersWhoAreInProgressOnTheCurrentVersionAssigningTheNewVesionWillResetTheLearnersProgressInThisCourse:
        "By creating this new version, learners who have completed the previous version will remain on that version. Any learners who have enrolled in the previous version but have not started will receive this new version. How would you like to apply this new version to Learners who are in progress on the current version? Assigning the new vesion will reset the Learner's progress in this course.",
    byCreatingThisNewVersionLearnersWhoHaveCompletedThePreviousVersionWillRemainOnThatVersionAnyLearnersWhoHaveEnrolledInThePreviousVersionButHaveNotStartedWillReceiveThisNewVersionYouWillBeAbleToChooseHowThisVersionIsAppliedToLearnersThatAreInProgressOnTheCurrentVersion:
        "By creating this new version, learners who have completed the previous version will remain on that version. Any learners who have enrolled in the previous version but have not started will receive this new version. You will be able to choose how this version is applied to learners that are in progress on the current version.",
    cannotEmulateUser: "Cannot Emulate User",
    certificateExpDateUtilsFormatDatetrueenrollmentCertificateExpirationDateToString:
        "Certificate Exp. {{DateUtilsFormatDatetrueenrollmentCertificateExpirationDateToString}}",
    certificateExpirationDateVariable: "Certificate Expiration Date: {{certificateExpirationDate}}",
    changeDescription: "Change Description",
    completionDateVariable: "Completion Date: {{completionDate}}",
    contactInformationHidden: "Contact Information Hidden",
    continue: "Continue",
    courseCompletionCriteria: "Course Completion Criteria",
    courseCompletionCriteriaILT:
        "Instructor-led event: You will need to attend all days of the class, complete and pass assessment(s), and complete the evaluation to receive a Certificate of Completion.",
    courseCompletionCriteriaOLL:
        "Online Learning course: You will need to complete all modules, complete/pass assessment(s), and complete the evaluation to receive a Certificate of Completion.",
    createAnAccount: "Create an Account",
    deactivatingCourseFailed: "Deactivating course failed.",
    deactivatingProductFailed: "Deactivating product failed.",
    deleteFile: "Delete File",
    downloadCertificates: "Download Certificates",
    duplicateEmailsWillBeRemovedFromTheFileImportAndWillNotBeIncludedOnTheImportSummaryReport:
        "Duplicate emails will be removed from the file import and will not be included on the Import Summary report.",
    emailMustBeInFirstColumn: "Email must be in first column.",
    enrolledLearners: "Enrolled Learners",
    enterSearchCriteria: "Enter search criteria...",
    eventDoesNotExist: "Event does not exist.",
    eventMustHaveANameAndType: "Event must have a name and type.",
    expExpirationDate: "EXP. {{expirationDate}}",
    failedToRetrieveContents: "Failed to retrieve contents.",
    failedToRetrieveInstructors: "Failed to retrieve instructors.",
    fileSizeIsTooLarge: "File size is too large.",
    generateAndDownloadCertificate: "Generate and Download Certificate",
    gettingProductsCountFailed: "Getting products count failed.",
    hour: "Hour",
    howToFacilitateLearningTheNFPAWay: "How to Facilitate Learning the NFPA Way",
    howWouldYouLikeToApplyThisNewVersionToLearnersWhoAreInProgressOnTheCurrentVersionAssigningTheNewVersionWillResetTheLearnersProgressInTheCourse:
        "How would you like to apply this new version to Learners who are in progress on the current version? Assigning the new version will reset the Learner's progress in the course.",
    IApproveAnException: "I approve an exception",
    instructors: "Instructors",
    isThisCertificateForHotWork: "Is this certificate for Hot Work?",
    lastSync: "Last Sync",
    launchNotYetImplementedForOnlineTraining: "Launch not yet implemented for online training.",
    learningPortal: "Learning Portal",
    nfpaLearningPortal: "NFPA Learning Portal",
    notSeeingChangesReflectedAbove: "Not seeing recent changes reflected above?",
    notSeeingLocationOrContactInformation:
        "Not seeing recent location or contact information changes reflected above?",
    or: "or",
    packageImportStatus: "Package Import Status",
    privacyPolicy: "Privacy Policy",
    productMustHaveANameTypeAndProductType: "Product must have a name, type, and product type.",
    ReasonForException: "Reason for exception",
    replaceFile: "Replace File",
    saveChangesSummary: "Save Changes Summary",
    selectAssignment: "Select Assignment",
    selectInstructors: "Select Instructors",
    signIn: "Sign In",
    startTime: "Start Time",
    syncData: "Sync Data",
    termsOfUse: "Terms of Use",
    theEvaluationWillBeAvailableToCompleteOnLastEventDate:
        "The evaluation will be available to complete on {{lastEventDate}}.",
    thereWasAnIssueCreatingTheNewVersion: "There was an issue creating the new version.",
    thereWasAnIssueUpdatingTheCourseDetails: "There was an issue updating the course details.",
    thereWasAnIssueUpdatingTheCourseVersion: "There was an issue updating the course version.",
    thereWasAProblemReturningCourseDetailsArchive:
        "There was a problem returning course details archive.",
    thisEventWasCanceledCanceledOnByCanceledByNameCanceledById:
        "This Event Was Canceled {{canceledOn}} by {{canceledByName}} {{canceledById}}",
    thisUsersInstructorRoleWasRemovedDeletedOnByFirstAndLastNameThisContentIsProvidedForHistoricalContextOnly:
        "This user's Instructor role was removed {{deletedOn}} by {{firstAndLastName}}. This content is provided for historical context only.",
    trainingAccessExpDateUtilsFormatDatetrueenrollmentExpirationDateToString:
        "Training Access Exp. {{DateUtilsFormatDatetrueenrollmentExpirationDateToString}}",
    typeToSearchByInstructorNameOrID: "Type to search by instructor name or ID",
    typeToSearchByOrganizationNameOrID: "Type to search by organization name or ID",
    unableToUpdateStatus: "Unable to update status.",
    unsupportedCultureCodeLocation: "Unsupported culture code location.",
    versionStatus: "Version Status",
    withdrawingEnrollmentFailed: "Withdrawing enrollment failed.",
    youAreActivatingVersionCourseVersionVersionId:
        "You Are Activating Version {{courseVersionVersionId}}",
    youAreBuildingAProductCourseTypeDisplayNamesproductOnlineLearningTypeToChangeToACourseOrSeries:
        "You are building a {{ProductCourseTypeDisplayNamesproductOnlineLearningType}}. To change to a Course or Series, ",
    youAreBuildingAProductCourseTypeDisplayNamesproductOnlineLearningTypeToChangeToALearningPath:
        "You are building a {{ProductCourseTypeDisplayNamesproductOnlineLearningType}}. To change to a Learning Path, ",
    youAreEditingTheCurrentVersion: "You Are Editing the Current Version",
    youMustAddAScormPackageToCreateANewVersion:
        "You must add a Scorm Package to create a new version!",
    yourNewVersionHasBeenCreatedAndTheZIPPackageIsCurrentlyProcessingOnceTheUploadIsCompleteMoveForwardToActivatingTheVersionToApplyIt:
        "Your new version has been created and the ZIP Package is currently processing. Once the upload is complete, move forward to activating the version to apply it.",
    youveCreatedVersionCourseVersionVersionId: "You've Created Version {{courseVersionVersionId}}",
    youWereWithdrawnFromThisTrainingOnWithdrawnDateForAnyQuestionsPleaseContactNFPASupport:
        "You were withdrawn from this training on {{withdrawnDate}}. For any questions please contact NFPA Support.",
};

const englishTranslations: CultureResources = {
    ...providedEnglishTranslations,
    ...internalEnglishTranslations,
};

// #endregion Translations

// -------------------------------------------------------------------------------------------------
// #region Culture
// -------------------------------------------------------------------------------------------------

const English: Culture<CultureResources> = cultureFactory(
    CultureCodes.ENGLISH,
    englishTranslations
);

// #endregion Culture

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { English };

// #endregion Exports
