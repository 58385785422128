import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { BadgeStyle } from "components/badges/badge/badge";
import { InstructorApplicationProfileStatus } from "./instructor-application-profile-status";

// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum InstructorApplicationStatus {
    Submitted = 0,
    MoreInfoNeeded = 1,
    TechReview = 2,
    PendingAssessment = 3,
    ReviewCompleteAccepted = 4,
    ReviewCompleteDeclined = 5,
    ContractInProgress = 6,
    Declined = 7,
    Completed = 8,
    Cancelled = 9,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const InstructorApplicationStatusDisplayNames: Map<InstructorApplicationStatus, TranslatedCopy> = {
    [InstructorApplicationStatus.Submitted]: "submitted",
    [InstructorApplicationStatus.Declined]: "declined",
    [InstructorApplicationStatus.MoreInfoNeeded]: "moreInfoNeeded",
    [InstructorApplicationStatus.TechReview]: "techReview",
    [InstructorApplicationStatus.PendingAssessment]: "pendingAssessment",
    [InstructorApplicationStatus.ReviewCompleteAccepted]: "reviewCompleteAccepted",
    [InstructorApplicationStatus.ReviewCompleteDeclined]: "reviewCompleteDeclined",
    [InstructorApplicationStatus.ContractInProgress]: "contractInProgress",
    [InstructorApplicationStatus.Completed]: "completed",
    [InstructorApplicationStatus.Cancelled]: "cancelled",
};

const InstructorApplicationStatusBadgeStyleMap: Map<InstructorApplicationStatus, BadgeStyle> = {
    [InstructorApplicationStatus.Submitted]: BadgeStyle.Warning,
    [InstructorApplicationStatus.Declined]: BadgeStyle.Error,
    [InstructorApplicationStatus.MoreInfoNeeded]: BadgeStyle.Warning,
    [InstructorApplicationStatus.TechReview]: BadgeStyle.Neutral,
    [InstructorApplicationStatus.PendingAssessment]: BadgeStyle.Neutral,
    [InstructorApplicationStatus.ReviewCompleteAccepted]: BadgeStyle.Neutral,
    [InstructorApplicationStatus.ReviewCompleteDeclined]: BadgeStyle.Error,
    [InstructorApplicationStatus.ContractInProgress]: BadgeStyle.Neutral,
    [InstructorApplicationStatus.Completed]: BadgeStyle.Success,
    [InstructorApplicationStatus.Cancelled]: BadgeStyle.Error,
};

const InstructorApplicationStatusBarLocation: Map<
    InstructorApplicationStatus,
    InstructorApplicationProfileStatus
> = {
    [InstructorApplicationStatus.Submitted]:
        InstructorApplicationProfileStatus.ApplicationSubmitted,
    [InstructorApplicationStatus.Declined]: InstructorApplicationProfileStatus.FinalReview,
    [InstructorApplicationStatus.MoreInfoNeeded]: InstructorApplicationProfileStatus.UnderReview,
    [InstructorApplicationStatus.TechReview]: InstructorApplicationProfileStatus.FinalReview,
    [InstructorApplicationStatus.PendingAssessment]:
        InstructorApplicationProfileStatus.PendingAssessment,
    [InstructorApplicationStatus.ReviewCompleteAccepted]:
        InstructorApplicationProfileStatus.FinalReview,
    [InstructorApplicationStatus.ReviewCompleteDeclined]:
        InstructorApplicationProfileStatus.FinalReview,
    [InstructorApplicationStatus.ContractInProgress]:
        InstructorApplicationProfileStatus.FinalReview,
    [InstructorApplicationStatus.Completed]: InstructorApplicationProfileStatus.FinalReview,
    [InstructorApplicationStatus.Cancelled]: InstructorApplicationProfileStatus.FinalReview,
};
// #endregion Maps

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export {
    InstructorApplicationStatus,
    InstructorApplicationStatusDisplayNames,
    InstructorApplicationStatusBadgeStyleMap,
    InstructorApplicationStatusBarLocation,
};

// #endregion Exports
